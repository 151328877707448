/* eslint-disable */
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthContext from "./context/AuthProvider";
import Cookies from 'js-cookie';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

// Fonts
import "./fonts/IBMPlexSans-Regular.ttf";
import "./fonts/Inter-Regular.ttf";


//Components
import Header from "./components/shared/header/Header";
import Dashboard from "./components/pages/Dashboard";
import AssetLibraryContainer from "./components/pages/AssetLibraryContainer";
import Campaign from "./components/pages/Campaign";
import CreateFolder from "./components/pages/asset-library/asset-list/CreateFolder";
import AssetDetails from "./components/pages/asset-library/asset-list/AssetDetails";
import Login from "./components/pages/login/Login";
import LoginTata from "./components/pages/login/LoginTata";
import NewAssetContainer from "./components/pages/asset-library/asset-upload/NewAssetContainer";
import SelectAssetType from "./components/pages/asset-library/asset-upload/SelectAssetType";
import AddingData from "./components/pages/asset-library/asset-upload/AddingData";
import CRMContainer from "./components/pages/CRMContainer";
import LoginToken from "./components/pages/login/LoginToken";
import UploadedData from "./components/pages/asset-library/asset-upload/UploadedData";
import GlobalFields from "./components/pages/asset-library/asset-upload/GlobalFields";
import Base from "./components/pages/asset-library/asset-upload/Base";
import PublishAsset from "./components/pages/asset-library/asset-upload/PublishAsset";
import Edit from "./components/pages/asset-library/asset-upload/Edit";
import AddTask from "./components/pages/crm/crm-list/leads/AddTask";
import AddNote from "./components/pages/crm/crm-list/leads/AddNote";
import DetailedLead from "./components/pages/crm/crm-list/leads/DetailedLead";
import LeadTabs from "./components/pages/crm/crm-list/leads/tab-components/LeadTabs";
import Imports from "./components/pages/crm/crm-list/leads/Imports";
import AddMail from "./components/pages/crm/crm-list/leads/AddMail";
import AddContact from "./components/pages/crm/crm-list/contacts-prospects/AddContact";
import SetupLeaderReport from "./components/setup/SetupLeaderReport";
import ConversionCtaList from "./components/pages/conversion/ConversionCtaList";
import LogoutHandler from "./components/pages/login/LogoutHandler"
//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import { fetchResetPasswordDetails, backToPreviousRoute, setAssetLibUnauthorized, logout, fetchCompanyLogo } from "./redux/actions/actions";
import { setCRMUnauthorized } from "./redux/actions/crmActions";
import { setUnauthorizedSetup } from "./redux/actions/setupActions";
import { setUnauthorizedConversion } from "./redux/actions/conversionAction";
import SetupMain from "./components/setup/SetupMain";
import { Fonts } from "react-bootstrap-icons";
import PageNotFound from "./components/pages/PageNotFound";
import VideoCta from "./components/pages/conversion/VideoCta";
import CampaignMain from "./components/pages/campaign/CampaignMain";
import EmailAssetDetail from "./components/pages/asset-library/asset-upload/EmailAssetDetail";
import PickTemplate from "./components/pages/asset-library/asset-upload/PickTemplate";
import ResetPassword from "./components/pages/login/ResetPssword";
import ArkLogin from "./components/pages/login/ArkLogin";
import BusinessMain from "./components/pages/mybusiness/BusinessMain";

function App() {
  const ctx = useContext(AuthContext);

  const dispatch = useDispatch();

  const authToken = localStorage.getItem("authToken")
    ? localStorage.getItem("authToken").slice(1, -1)
    : "";
  const userType = localStorage.getItem("userType")
    ? localStorage.getItem("userType").slice(1, -1)
    : "";

  const [pageType, getPageType] = useState("");
  const [topMenuData, getTopMenuData] = useState([]);
  const [folderId, getFolderId] = useState("");
  const [folderName, getFolderName] = useState("");
  const [folderType, getFolderType] = useState("");
  const [assetDetails, getAssetDetails] = useState({});
  const [searchKey, getSearchKey] = useState("");
  const [timeInterval, setTimeInterval] = useState("");

  const assetUnauthorized = useSelector((state) => state.assetLibrary.unauthorizedAssetLibrary);
  const backToPreviousUrl = useSelector((state) => state.assetLibrary.backToPreviousUrl);
  const conversionUnauthorized = useSelector((state) => state.conversionData.unAuthorisedConversion);
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  const assetUplodUnauthorized = useSelector((state) => state.assetUpload.unauthorizedAssetUpload);
  const crmUnauthorized = useSelector((state) => state.crmLeads.unAuthorisedCrm);
  const setupUnauthorized = useSelector((state) => state.setupScreen.unAuthorisedSetup);

  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);

  const pageTypeHandler = (title) => {
    getPageType(title);
  };

  const topMenuDataHandler = (data) => {
    getTopMenuData(data);
  };

  const folderHandler = (id, name, type) => {
    getFolderId(id);
    getFolderName(name);
    getFolderType(type);
  };

  const setFolderName = (name) => {
    getFolderName(name);
  };

  const assetDetailsHandler = (contentDetails) => {
    getAssetDetails(contentDetails);
  };

  const searchAsset = (searchKey) => {
    getSearchKey(searchKey);
  };

  // useEffect(() => {
  //   const handleTabClose = async () => {
  //     await dispatch(backToPreviousRoute(myItem));
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);

  if (window.location.href.indexOf("?token") > -1) {
    let tempUrl = `${window.location.href}`;
    //let url_string = "https://app.spdevmfp.com/react-ui/build/?token=VlRGQ1JWTlVTWGhPVkZFd1RVUk5lRTE2U1RWTlVUMDk&c=aklewl-wewewdcdc-wdss"; //window.location.href
    let url = new URL(tempUrl);
    let currToken = url.searchParams.get("token");
    //console.log("searchParams",currToken);
    //let currToken = tempUrl.split("?token=")[1];
    localStorage.setItem("tokenLogin", currToken);
  }
  let originUrl = window.location.origin
  let tempUrl = `${window.location.href}`;
  let setUrl;

  if (tempUrl.search("localhost") > 0) {
    // setUrl = "/sp-bfsi/rapp/";
    setUrl = "/home";
  } else {
    setUrl = "/home/";
  }

  if (assetUnauthorized == 401 || assetUnauthorized == 403) {
    dispatch(logout(authToken));
    dispatch(setAssetLibUnauthorized(null));
    setTimeout(() => {
      var myItem = backToPreviousUrl;
      localStorage.clear();
      window.location.reload();
      dispatch(backToPreviousRoute(myItem));
    }, 2000);
  } else if (crmUnauthorized == 401 || crmUnauthorized == 403) {
    dispatch(logout(authToken));
    dispatch(setCRMUnauthorized(null));
    setTimeout(() => {
      var myItem = backToPreviousUrl;
      localStorage.clear();
      window.location.reload();
      dispatch(backToPreviousRoute(myItem));
    }, 2000);
  }
  else if (setupUnauthorized == 401 || setupUnauthorized == 403) {
    dispatch(logout(authToken));
    dispatch(setUnauthorizedSetup(null));
    setTimeout(() => {
      var myItem = backToPreviousUrl;
      localStorage.clear();
      window.location.reload();
      dispatch(backToPreviousRoute(myItem));
    }, 2000);
  }
  else if (conversionUnauthorized == 401 || conversionUnauthorized == 403) {
    dispatch(logout(authToken));
    dispatch(setUnauthorizedConversion(null));
    setTimeout(() => {
      localStorage.clear();
      window.location.reload();
    }, 2000);
  }
  else {
    if (window.location.pathname !== "/home/" && window.location.pathname !== "/home/arklogin" && window.location.pathname !== "/home/sp-reset-password" && !window.location.pathname.includes("/logout") && !window.location.pathname.includes("manager/") && !window.location.pathname.includes("framework/")) {
      let tempUrlActive = window.location.pathname.replace("/home", "")
      dispatch(backToPreviousRoute(tempUrlActive));
    }
  }

  const userTimer = () => {
    // console.log("timeInterval>>", timeInterval);
    // console.log("TIMER");
  }

  const LoginRoute = () => {
    return talic_active === "tal" || window.location.host === 'app.tataaiapartner.com' ? <LoginTata title="Login" /> : <Login title="Login" />;
  }

  const SpecCondition = () => {

    if ((tempUrl.search("technochimes") < 0 && userType == "C" && Cookies.get('SPSESSTKN') && tempUrl.search("tataaiapartner") < 0 && tempUrl.search("digipartnerconnect") < 0)) {
      if (backToPreviousUrl === "/dashboard/report") {
        return <Navigate to={backToPreviousUrl} />
      } else {
        window.location = `${originUrl}/manager/template-dashboard.php`;
      }
    }
    else {
      return backToPreviousUrl ? <Navigate to={backToPreviousUrl} /> : <Navigate to="/AssetLibrary" />
    }
    return

  }

  // function userTimer() {
  //   // console.log("assetUnauthorized/assetUplodUnauthorized", assetUnauthorized,assetUplodUnauthorized);
  //   // console.log('each 5 second...');
  //   if (assetUnauthorized == 403) {
  //     clearInterval(myInterval);
  //     dispatch(setAssetLibUnauthorized(null));
  //     setTimeout(() => {
  //       localStorage.clear();
  //       window.location.reload();
  //     }, 2000);
  //   } else if (crmUnauthorized == 403) {
  //     clearInterval(myInterval);
  //     dispatch(setCRMUnauthorized(null));
  //     setTimeout(() => {
  //       localStorage.clear();
  //       window.location.reload();
  //     }, 2000);
  //   }
  // }
  useEffect(() => {
    if (Cookies.get('SPSESSTKN') == undefined || Cookies.get('SPSESSTKN') == 'undefined' || Cookies.get('SPSESSTKN') == '') {
      localStorage.clear();
      // return <Navigate to="/home/" />;
    }
  }, []);

  useEffect(() => {
    // if (window.location.href.indexOf("sp-reset-password?") > -1) {
    //   let resetPasswordUrl = window.location.href.split('?').pop();
    //   let setUrl = window.location.href;
    //   setUrl = setUrl.split('/')[0] + "/" + setUrl.split('/')[1] + "/" + setUrl.split('/')[2] + "/";
    //   //let newUrl = "http://localhost/sp-bfsi/framework/api/sp-reset-password?" + resetPasswordUrl;
    //   let newUrl = `${setUrl}framework/api/sp-reset-password?` + resetPasswordUrl;
    //   // console.log("setUrl",setUrl);
    //   // console.log("newUrl",newUrl);
    //   dispatch(fetchResetPasswordDetails(newUrl));
    //   localStorage.setItem("resetPassword", newUrl);
    //   setTimeout(() => {
    //     // window.location.href = "http://localhost:3000";
    //     window.location.href = setUrl + 'home/';
    //   }, 2000);
    // };
    // if (company_logo === null || company_logo?.new_login) {
    dispatch(fetchCompanyLogo());
    // }
  }, []);

  useEffect(() => {
    // document.getElementById("companyIcon");

    let oldFavicon = document.getElementById('companyIcon')
    var link = document.createElement('link')
    link.id = 'favicon';
    link.type = 'image/x-icon'
    link.rel = 'icon';
    link.href = company_logo?.favicon;
    if (oldFavicon) {
      document.head.removeChild(oldFavicon);
    }
    document.head.appendChild(link);
    document.title = company_logo?.title;
    // let title = document.getElementById('title');
    // title = company_logo?.title;

  }, [company_logo]);

  useEffect(() => {
    if (userType == "C") {
      // console.log("IF");
      // console.log("timeInterval", timeInterval);
      setTimeInterval(setInterval(userTimer, 5000))
    } else {
      console.log("ELSE");
      if (timeInterval) {
        window.location.reload();
      } else {
        // console.log("timeInterval", timeInterval);
        clearInterval(setTimeInterval(null));
      }
    }
  }, [userType]);

  // useEffect(() => {
  //   // if(resetPasswordStatus) {
  //   //   navigate("/reset-password");
  //   // }
  //   console.log("resetPassword",resetPassDetails);
  //   if (resetPassDetails && resetPassDetails.response.email && resetPassDetails.response.resetkey) {
  //     window.location.href = "http://localhost:3000/resetpassword";
  //   }
  // }, []);

  return (
    <main>
      <BrowserRouter basename={setUrl}>
        {authToken && (
          <Header
            pageType={pageType}
            authToken={authToken}
            userType={userType}
            // folderId={folderId}
            // folderName={folderName}
            // folderType={folderType}
            topMenuDataHandler={topMenuDataHandler}
            //setFolderName={setFolderName}
            topMenuData={topMenuData}
            searchAsset={searchAsset}
          />
        )}
        <Routes>
          {localStorage.getItem("tokenLogin") ? (
            <Route path="/" element={<LoginToken title="LoginToken" />} />
          ) : (
            <Route
              path="/"
              element={
                !authToken && authToken == '' ? (
                  <LoginRoute />
                ) : <SpecCondition />
              }
            />
          )}
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                title="Dashboard"
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
              />
            }
          />

          <Route
            path="/dashboard/report"
            element={
              <SetupLeaderReport
                pageTypeHandler={pageTypeHandler}
                pageName="leaderBoard"
                subPageName="report"
                displayType="1"
                topTitle="Leader Board"
                authToken={authToken} />
            }
          />
          
          <Route
            path="/arklogin"
            element={
              <ArkLogin />
            }
          />
          <Route path="/mybusiness">
            <Route index={true} element={<BusinessMain pageTypeHandler={pageTypeHandler} pageName="business" subPageName="review" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="profile" index={false} element={<BusinessMain pageTypeHandler={pageTypeHandler} pageName="business" subPageName="profile" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="profile/report" index={false} element={<BusinessMain pageTypeHandler={pageTypeHandler} pageName="business" subPageName="report" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="profile/update" index={false} element={<BusinessMain pageTypeHandler={pageTypeHandler} pageName="business" subPageName="update" displayType="1" topTitle="" authToken={authToken} />} />
          </Route>
          
          <Route path="/campaigns">
            <Route index={true} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="direct" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="indirect" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="indirect" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="list" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="list" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="contactlist" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="contact_list" displayType="1" topTitle="" authToken={authToken} />} />
            <Route path="run_campaign" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="run_campaign" displayType="2" topTitle="Run Email Campaign" authToken={authToken} />} />
            <Route path="push_campaign" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="push_campaign" displayType="2" topTitle="Push Email Campaign" authToken={authToken} />} />
            <Route path="run_template" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="run_template" displayType="2" topTitle="" authToken={authToken} />} />
            <Route path="push_template" index={false} element={<CampaignMain pageTypeHandler={pageTypeHandler} pageName="campaign" subPageName="push_template" displayType="2" topTitle="" authToken={authToken} />} />
          </Route>
          
          <Route path="conversion/cta" element={<ConversionCtaList pageTypeHandler={pageTypeHandler} pageName="conversion" subPageName="cta" authToken={authToken} />} />
          <Route path="conversion/videoCta" element={<VideoCta pageName="conversion" subPageName="videoCta" authToken={authToken} />} />

          <Route path="/setup" >
            <Route index={true} element={<SetupMain pageTypeHandler={pageTypeHandler} authToken={authToken} displayType="1" pageName="Setup" />} />
            {/* <Route path=":" /> */}
            <Route path="setup/microsite" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} displayType="1" pageName="microsite" authToken={authToken} />} />
            <Route path="setup/mobileApp" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} displayType="1" pageName="mobileApp" authToken={authToken} />} />
            <Route path="setup/crm" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} displayType="1" pageName="crm" authToken={authToken} />} />
          </Route>

          <Route path="/setup/userManagement">
            <Route index={true} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="userManagement" subPageName="partner" displayType="1" topTitle="Partner List" authToken={authToken} />} />
            <Route path="/setup/userManagement/partnerCategory" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="userManagement" subPageName="partnerCategory" displayType="1" topTitle="Partner Category List" authToken={authToken} />} />
            <Route path="/setup/userManagement/editPartner" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="userManagement" subPageName="editPartner" displayType="2" topTitle="Edit Partner" innerTitle="Edit User" authToken={authToken} />} />
            <Route path="/setup/userManagement/addPartner" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="userManagement" subPageName="addPartner" displayType="2" topTitle="Add Partner" innerTitle="Add User" authToken={authToken} />} />
          </Route>
          <Route path="/setup/leaderBoard">
            <Route index={true} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="leaderBoard" subPageName="leaderBoard" displayType="1" topTitle="LeaderBoard" authToken={authToken} />} />
            <Route path="/setup/leaderBoard/levels" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="leaderBoard" subPageName="levels" displayType="1" topTitle="Levels" authToken={authToken} />} />
            <Route path="/setup/leaderBoard/levels/addBatches" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="levels_batch" subPageName="addBatches" topTitle="Levels Batch Setup" innerTitle="Create Batch" displayType="2" authToken={authToken} />} />
            <Route path="/setup/leaderBoard/levels/editBatches" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="levels_batch" subPageName="editBatches" topTitle="Levels Batch Setup" innerTitle="Edit Batch" displayType="2" authToken={authToken} />} />
          </Route>

          <Route path="/setup/content" >
            <Route index={true} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="category" displayType="1" topTitle="Content Setup" authToken={authToken} />} />
            <Route path="/setup/content/imageLibrary" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="imageLibrary" displayType="1" topTitle="All Assets" authToken={authToken} />} />
            <Route path="/setup/content/contentList" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="contentList" displayType="1" topTitle="Content List Setup" authToken={authToken} />} />
            <Route path="/setup/content/businessNeed" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="businessNeed" displayType="1" topTitle="Business Need" authToken={authToken} />} />
            <Route path="/setup/content/hashtag" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="hashtag" displayType="1" topTitle="Hashtag list" authToken={authToken} />} />
            <Route path="/setup/content/hashtagGroup" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="hashtagGroup" displayType="1" topTitle="Hashtag Groups" authToken={authToken} />} />
            <Route path="/setup/content/globalSearch" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="globalSearch" displayType="1" topTitle="Global Search" authToken={authToken} />} />
            <Route path="/setup/content/productType" index={false} element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content" subPageName="productType" displayType="1" topTitle="Product Types" authToken={authToken} />} />
          </Route>

          {/* category list */}
          <Route path="/setup/content/addCategory" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_cat" subPageName="addCategory" topTitle="Category List" innerTitle="Create Category" displayType="2" authToken={authToken} />} />
          <Route path="/setup/content/addBenefit" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_cat" subPageName="addBenefit" topTitle="Category List" innerTitle="Add Benefits" displayType="2" authToken={authToken} />} />
          <Route path="/setup/content/addFaq" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_cat" subPageName="addFaq" topTitle="Category List" innerTitle="Add Faq" displayType="2" authToken={authToken} />} />
          <Route path="/setup/content/editCategory" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_cat" subPageName="editCategory" topTitle="Category List" innerTitle="Edit Category" displayType="2" authToken={authToken} />} />

          {/* Content List */}
          <Route path="/setup/content/contentList/addContent" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_list" subPageName="addContent" topTitle="Content List Setup" innerTitle="New Content" displayType="2" authToken={authToken} />} />
          <Route path="/setup/content/contentList/editContent" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_list" subPageName="editContent" topTitle="Content List Setup" innerTitle="Edit Content" displayType="2" authToken={authToken} />} />

          {/* Hashtag Groups */}
          <Route path="/setup/content/hashtagGroup/createGroup" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_hashtag" subPageName="createGroup" topTitle="New Hashtag Group" innerTitle="" displayType="1" authToken={authToken} />} />
          <Route path="/setup/content/hashtagGroup/editGroup" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_hashtag" subPageName="editGroup" topTitle="New Hashtag Group" innerTitle="" displayType="1" authToken={authToken} />} />

          {/* Product_type list */}
          <Route path="/setup/content/addProductType" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_p_type" subPageName="addProductType" topTitle="Product Type" innerTitle="New Product Type" displayType="2" authToken={authToken} />} />
          <Route path="/setup/content/editProductType" element={<SetupMain pageTypeHandler={pageTypeHandler} pageName="content_p_type" subPageName="editProductType" topTitle="Product Type" innerTitle="Edit Product Type" displayType="2" authToken={authToken} />} />

          <Route
            path="/AssetLibrary"
            element={
              <AssetLibraryContainer
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
                userType={userType}
                folderHandler={folderHandler}
                assetDetailsHandler={assetDetailsHandler}
                folderName={folderName}
                folderType={folderType}
                folderId={folderId}
                setFolderName={setFolderName}
                searchKey={searchKey}
              />
            }
          />
          {/*  <Route
            path="/crm"
            element={
              <CRMContainer
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
              //authToken={loginData.token}
              />
            }
          >
          </Route> */}
          {/* <Route path="crm/:id" element={<DetailedLead   pageTypeHandler={pageTypeHandler}
                authToken={authToken}/>}>
            <Route index path="add-task" element={<AddTask />} />
            <Route path="add-note" element={<AddNote />} />
          </Route> */}


          {/* <Route path="/crm"> */}
          <Route path="/crm" >
            <Route index={true} element={<CRMContainer pageTypeHandler={pageTypeHandler} authToken={authToken} pageName="leads" />} />
            {/* <Route path=":" /> */}
            <Route path="contacts" index={false} element={<CRMContainer pageTypeHandler={pageTypeHandler} pageName="contacts" authToken={authToken} />} />
            <Route path="prospects" index={false} element={<CRMContainer pageTypeHandler={pageTypeHandler} pageName="prospects" authToken={authToken} />} />
            <Route path="accounts" index={false} element={<CRMContainer pageTypeHandler={pageTypeHandler} pageName="accounts" authToken={authToken} />}>
            </Route>
          </Route>

          <Route path="crm/leads/:id" element={<DetailedLead pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />}>
            <Route index={true} element={<LeadTabs pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
            <Route path="note/:name" index={false} element={<AddNote pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
            <Route path="note/:name-:noteId" index={false} element={<AddNote pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
            <Route path="task/:name" index={false} element={<AddTask pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
            <Route path="task/:name-:taskId" index={false} element={<AddTask pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
            <Route path="add-mail" index={false} element={<AddMail pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
          </Route>

          <Route path="crm/leads/import" element={<Imports pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Leads" />} />
          <Route path="crm/contacts/add-contact" element={<AddContact pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Contacts" />} />
          <Route path="crm/prospects/add-contact" element={<AddContact pageTypeHandler={pageTypeHandler} authToken={authToken} pageFrom="Prospects" />} />

          <Route path="/logout" element={<LogoutHandler pageTypeHandler={pageTypeHandler} authToken={authToken} />} />
          <Route path="/sp-reset-password" element={<ResetPassword pageTypeHandler={pageTypeHandler} authToken={authToken} />} />
          <Route
            path="/campaign"
            element={
              <Campaign
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
              />
            }
          />
          <Route
            path="/create-folder"
            element={
              <CreateFolder
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
                purpose="create"
              />
            }
          />
          <Route
            path="/edit-folder"
            element={
              <CreateFolder
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
                purpose="edit"
              />
            }
          />
          <Route
            path="/asset-details"
            element={
              <AssetDetails
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
                userType={userType}
              />
            }
          />
          <Route
            path="/new-asset"
            element={
              <NewAssetContainer
                pageTypeHandler={pageTypeHandler}
                authToken={authToken}
              />
            }
          >
            <Route index element={<SelectAssetType pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="upload-asset" element={<AddingData pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="global-asset-details" element={<GlobalFields pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="base-asset-details" element={<Base pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="uploaded-asset" element={<UploadedData pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="publish-asset" element={<PublishAsset pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="edit-asset-details" element={<Edit pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="upload-email-asset" element={<EmailAssetDetail pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            <Route path="pick-template" element={<PickTemplate pageTypeHandler={pageTypeHandler}
              authToken={authToken} />} />
            {/* /* <Route path="adding-data" element={<AddingData />} /> */}
          </Route>
          {/* <Route
            path="/"
            element={
              !authToken ? (
                <Login title="Login" />
              ) : (
                <Navigate to="/crm/leads" />
              )
            }
          /> */}
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  );
}

export default App;
