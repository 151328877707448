import storeApis from "../../api/storeApi";
import "react-toastify/dist/ReactToastify.min.css";
import * as types from "../../constants/ActionTypes";
import _ from "lodash";
import Toast from "../../components/shared/Toast";


const DIRECT_LIST_URL = "/framework/api/emailCampaign";

// SET Unauthorization Status
export const setUnauthorizedSetup = () => {
  return (dispatch) => {
    dispatch({ type: "UNAUTHORIZED_SETUP", payload: null });
  };
};

export const seletedTemplateData = (value) => {
    return (dispatch) => {
        dispatch({ type: "CURRENT_TEMPLATE_DATA", payload: value });
    };
};

export const setCurrentPagePag = (value) => {
    return (dispatch) => {
        dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: value });
    };
};

export const currentUrlPath = () => {
    return (dispatch) => {
        dispatch({ type: "CURRENT_URL_PATH", payload: null });
    };
};

export const searchInput = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_INPUT, payload: value });
    };
};

export const setRunCampaignData = (value) => {
    return (dispatch) => {
        dispatch({ type: types.RUN_CAMPAIGN_DATA, payload: value });
    };
};

export const setshowEnteries = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SHOW_ENTERY, payload: value });
    };
}

export const setContactListId = (value, onSuccessListId) => {
    return (dispatch) => {
        dispatch({ type: types.NEW_LIST_ID, payload: value });
        onSuccessListId(value);
    };
}

export const setSelectedListIds = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SELECTED_LIST_ID, payload: value });
    };
}

// fetch BusinessNeed Listing 
export const fetchBusinessProfileData = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/fetchProfile",
                {
                    profile_id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PROFILE_DATA, payload: response.data.response });
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
        }
    };
};

// fetch BusinessNeed Listing 
export const fetchReviewListing = (authToken, sync, search, page, filter, startDate, endDate) => {
    let filterData
    if (filter == 1) {
        filterData = "DAY"
    } else if (filter == 2) {
        filterData = "WEEK"
    } else if (filter == 3) {
        filterData = "MONTH"
    } else {
        filterData = null
    }
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/fetchReviews",
                {
                    sync: sync, // value should be 0 or 1
                    search: search,
                    page: page,
                    limit: 20,
                    sort_by: filterData,
                    to_date: endDate,
                    from_date: startDate
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_REVIEW_LIST, payload: null });
                    await dispatch({ type: types.FETCH_REVIEW_LIST, payload: response.data.response });
                    dispatch({ type: "BUSINESS_CURRENT_PAGE", payload: page });
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch Profile Listing 
export const fetchProfileListing = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/profileListing",
                {
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PROFILE_LIST, payload: null });
                    await dispatch({ type: types.FETCH_PROFILE_LIST, payload: response.data.response });
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};


// fetch BusinessNeed Listing 
export const fetchAnalyticsData = (authToken, id, filter, start_date, end_date) => {
    let filterData
    if (filter == 1) {
        filterData = "DAY"
    } else if (filter == 2) {
        filterData = "WEEK"
    } else if (filter == 3) {
        filterData = "MONTH"
    } else {
        filterData = null
    }
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/getGoogleAnalytics",
                {
                    profile_id: id,
                    start_date: start_date,
                    end_date: end_date,
                    sort_by: filterData
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_ANALYTICS_DATA, payload: response.data.response?.google_response });
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
        }
    };
};


export const submitPostReply = (authToken, reply, id) => {
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/replyReview",
                {
                    id: id,
                    reply: reply
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    dispatch(fetchReviewListing(authToken, 0, "", 1, null, null, null));
                    Toast(response.data.message, "S");
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const deletePostReply = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/deleteReview",
                {
                    id: id,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    dispatch(fetchReviewListing(authToken, 0, "", 1, null, null, null));
                    Toast(response.data.message, "S");
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const handleSaveProfileData = (authToken, id, name, phone, website, onSuccess) => {
    return async (dispatch) => {
        dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "framework/api/updateProfile",
                {
                    profile_id: id,
                    title: name,
                    phone: phone,
                    websiteUri: website  
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "BUSINESS_TOGGLE_LOADING", payload: false });
                    onSuccess();
                    Toast(response.data.message, "S");
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};