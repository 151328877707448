import React from "react";
import * as Icon from "react-bootstrap-icons";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchBusinessProfileData, handleSaveProfileData } from "../../../redux/actions/businessAction";
import Loading from "../../Loading";

const BusinessProfileEdit = (props) => {  
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const isLoading = useSelector((state) => state.mybusiness.business_loading);
  const profile_data = useSelector((state) => state.mybusiness.profile_data);
   
  useEffect(() => {
    if (id) {
      dispatch(fetchBusinessProfileData(props.authToken, id));
    } else {
      navigate(`/mybusiness/profile`);
    }  
  }, [id])
  
  useEffect(() => {
    if (profile_data) {
      setTitle(profile_data?.title);
      setPhone(profile_data?.phoneNumbers);
      setWebsite(profile_data?.websiteUri)
    }
  }, [profile_data])
  
  const onSuccess = () => {
    navigate(`/mybusiness/profile`)
  }

  return (
    <div>
      {isLoading && <Loading />}
      <div className="col-12 d-flex align-item-center mt-3">
        <div className='w-25 mb-4'>
            <span className="cursor-pointer" onClick={()=>{navigate(`/mybusiness/profile`)}}><Icon.ChevronLeft color='#45bcd2'/> Back to profile </span>
          </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-5">
          <div>
            <Form>
              <div className="d-flex align-items-center justify-content-between">
                <label>Title*</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={title}
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>Website*</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={website}
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>Address 2</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.address2}
                  type="text"
                  placeholder=""
                  disabled
                />
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <label>City</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.city}
                  type="text"
                  placeholder=""
                  disabled
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>State</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.state}
                  type="text"
                  placeholder=""
                  disabled
                />
              </div>
            </Form>
          </div>
        </div>
        <div className="col-6">
          <div>
            <Form>
              <div className="d-flex align-items-center justify-content-between">
                <label>Phone*</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={phone}
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>Address</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.address1}
                  type="text"
                  placeholder=""
                  disabled
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>Address 3</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.address3}
                  type="text"
                  placeholder=""
                  disabled
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>Postal Code</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.pincode}
                  type="text"
                  placeholder=""
                  disabled
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <label>Region Code</label>
                <Form.Control
                  className="add-name my-3 width-input"
                  value={profile_data?.region_code}
                  type="text"
                  placeholder=""
                  disabled
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="custom-btn mt-2">
        <Button
          variant="info"
          type="submit"
          className="business-save-btn justify-content-between d-flex m-lg-0"
          onClick={() => {
            dispatch(handleSaveProfileData(props.authToken, id, title, phone, website, onSuccess))
          }}
        >
          Save
          <span>
            <Icon.ChevronRight />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default BusinessProfileEdit;
