/* eslint-disable */
import { useRef, useState, useEffect, useContext } from "react";
import CryptoJS from "crypto-js";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import Page from "../../Page";
import { Container, Row, Col, Form, Button, Alert, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import logo from "../../../assets/logoTata.png";
import "./Login.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

let md5 = require("md5");

const Login = (props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const authCtx = useContext(AuthContext);
  const userRef = useRef();
  const LOGIN_URL = authCtx.loginUrl;
  const GET_OTP_URL = authCtx.getOtpUrl;
  const OTP_LOGIN_URL = authCtx.otpLoginUrl;
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [success, setSuccess] = useState(false);
  
  // New states for OTP functionality
  const [loginOption, setLoginOption] = useState(1); // 1 for password, 3 for OTP
  const [otpStatus, setOtpStatus] = useState(false);
  const [contact, setContact] = useState(null);
  const [saveUsername, setSaveUsername] = useState("");

  // Handle mobile number input validation
  const handleMobileInput = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digits
    if (value.length <= 10) { // Only update if length is 10 or less
      setUsername(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate mobile number when sending OTP
    if (loginOption === 3 && !otpStatus && username.length !== 10) {
      setShow(true);
      setErrMsg("Please enter a valid 10-digit mobile number");
      return;
    }
    
    setButtonLoader(true);
    setErrMsg("");
    
    if (loginOption === 3) {
      // OTP login flow
      if (otpStatus) {
        // Verify OTP
        try {
          const response = await axios.post(OTP_LOGIN_URL, {
            otp: username,
            username: contact
          });
          if (response?.data?.statusCode === "401") {
            setShow(true);
            setErrMsg(response?.data?.message); 
          } else if (response?.data?.statusCode === "1012") {
            setShow(true);
            setErrMsg(
              "Login Failed..! You have reached maximum attempt limit. Please try after 3hrs."
            );
          } else if (response?.data?.statusCode === "1008") {
            setShow(true);
            setErrMsg("Unauthorized");
          } else {
            setShow(false);
            setErrMsg(" ");
            authCtx.onLogin(response?.data);
          }
          setUsername("");
          setButtonLoader(false);
        } catch (err) {
          if (!err?.response) {
            setShow(true);
            setErrMsg("No Server Response");
          } else if (err.response?.status === 400) {
            setShow(true);
            setErrMsg("Invalid OTP");
          } else if (err.response?.status === 401) {
            setShow(true);
            setErrMsg("Unauthorized");
          } else {
            setShow(true);
            setErrMsg("Login Failed");
          }
          setButtonLoader(false);
        }
      } else {
        // Send OTP
        try {
          const response = await axios.post(GET_OTP_URL, {
            username: username,
          });
          if (response?.data?.statusCode === "200") {
            setContact(response?.data.contact);
            setShow(true);
            setSuccess(true);
            setOtpStatus(true);
            setErrMsg(response?.data?.message);
            setSaveUsername(username);
            setUsername("");
          } else {
            setShow(true);
            setSuccess(false);
            setErrMsg(response?.data?.message || "Failed to send OTP. Please try again.");
          }
          setButtonLoader(false);
        } catch (err) {
          setShow(true);
          setSuccess(false);
          setErrMsg("Failed to send OTP. Please try again.");
          setButtonLoader(false);
        }
      }
    } else {
      // Password login flow
      let finalEncrytedPass = passwordEncryptionCipher(password);
      try {
        const response = await axios.post(LOGIN_URL, {
          user_id: username,
          password: finalEncrytedPass,
        });

        if (response?.data?.statusCode === "401") {
          setShow(true);
          setErrMsg(response?.data?.message);
        } else if (response?.data?.statusCode === "1012") {
          setShow(true);
          setErrMsg(
            "Login Failed..! You have reached maximum attempt limit. Please try after 3hrs."
          );
        } else if (response?.data?.statusCode === "1008") {
          setShow(true);
          setErrMsg("Unauthorized");
        } else {
          setShow(false);
          setErrMsg(" ");
          authCtx.onLogin(response?.data);
        }
        setButtonLoader(false);
        setUsername("");
        setPassword("");
      } catch (err) {
        if (!err?.response) {
          setShow(true);
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setShow(true);
          setErrMsg("Invalid User ID or Password");
        } else if (err.response?.status === 401) {
          setShow(true);
          setErrMsg("Unauthorized");
        } else {
          setShow(true);
          setErrMsg("Login Failed");
        }
        setButtonLoader(false);
      }
    }
  };
  
  const passwordEncryptionCipher = (inputPassword) => {
    const string = inputPassword;
    const secretKey = CryptoJS.enc.Utf8.parse("Xp2s5v8y/B?E(H+KbPeShVmYq3t6w9z$");
    const initializationVector = CryptoJS.enc.Utf8.parse("RandomInitVector");

    // Encryption
    const cipherNode = CryptoJS.AES.encrypt(string, secretKey, {
      iv: initializationVector,
      mode: CryptoJS.mode.CBC
    });
    const encryptedString = CryptoJS.enc.Base64.stringify(cipherNode.ciphertext);
    return encryptedString;
  };
  
  const resendOTPHandler = async () => {
    setShow(false);
    setSuccess(false);
    
    // If we don't have a saved username but have current username input
    const usernameToUse = saveUsername || username;
    
    // Don't proceed if we don't have any username to use
    if (!usernameToUse) return;
    
    try {
      const response = await axios.post(GET_OTP_URL, {
        username: usernameToUse,
      });
      if (response?.data?.statusCode === "200") {
        setContact(response?.data.contact);
        setShow(true);
        setSuccess(true);
        setOtpStatus(true);
        setErrMsg(`New ${response?.data?.message}`);
        
        // Save the username if not already saved
        if (!saveUsername) {
          setSaveUsername(usernameToUse);
          setUsername("");
        }
      } else {
        setShow(true);
        setSuccess(false);
        setErrMsg(response?.data?.message || "Failed to send OTP. Please try again.");
      }
    } catch (err) {
      setShow(true);
      setSuccess(false);
      setErrMsg("Failed to send OTP. Please try again.");
    }
  }

  useEffect(() => {
    setAuthToken(authCtx.auth);
  }, [setAuthToken, authCtx.auth, authToken]);

  return (
    <>
      <Page title={props.title}>
        <Row>
          <Col className="col-lg-4 col-md-6 col-sm-8 col-11 col" style={{ margin: "auto" }}>
            {company_logo && company_logo?.logo && <Row style={{ textAlign: "center" }}>
                <Col>
                  <br />
                  <br />
                  <img src={company_logo?.logo} alt="Company Logo" className="login-company-logo"/>
                  <br />
                  <br />
                </Col>
              </Row>}
            <Row style={{ textAlign: "center" }}>
              <Col>
                <br />
                <h3 className="title">Login</h3>
                <br />
              </Col>
            </Row>
            <Container className="login">
              <Row>
                <Col>
                  {show && (
                    <Alert
                      variant={success ? "success" : "danger"}
                      onClose={() => {
                        setShow(false);
                        setSuccess(false);
                      }}
                      dismissible
                    >
                      <Alert.Heading>
                        <Icon.ExclamationTriangle />
                      </Alert.Heading>
                      <p>{errMsg}</p>
                    </Alert>
                  )}

                  <Form onSubmit={handleSubmit}>
                    <div className="d-flex mb-4">
                      <Form.Group
                        className="me-4 radio-btn"
                        controlId="havePassword"
                      >
                        <Form.Check
                          type="radio"
                          label="Password"
                          name="loginOption"
                          checked={loginOption === 1 ? true : false}
                          onChange={() => { setLoginOption(1); setOtpStatus(false); setUsername(""); }}
                        />
                      </Form.Group>

                      <Form.Group
                        className="radio-btn"
                        controlId="sendOTP"
                      >
                        <Form.Check
                          type="radio"
                          label="OTP"
                          name="loginOption"
                          checked={loginOption === 3 ? true : false}
                          onChange={() => { setLoginOption(3); setUsername(""); }}
                        />
                      </Form.Group>
                    </div>

                    {otpStatus ? 
                      <Form.Group className="mb-3" controlId="username">
                        <Form.Control
                          type="text"
                          ref={userRef}
                          autoComplete="off"
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                          required
                          placeholder="Enter OTP"
                        />
                      </Form.Group> 
                      : 
                      <Form.Group className="mb-3" controlId="username">
                        <Form.Control
                          type={loginOption === 3 ? "tel" : "text"}
                          ref={userRef}
                          autoComplete="off"
                          onChange={loginOption === 3 ? handleMobileInput : (e) => setUsername(e.target.value)}
                          value={username}
                          required
                          maxLength={loginOption === 3 ? "10" : undefined}
                          pattern={loginOption === 3 ? "[0-9]{10}" : undefined}
                          placeholder={loginOption === 1 ? "User ID" : "Mobile number"}
                        />
                      </Form.Group>
                    }

                    {loginOption === 1 && (
                      <Form.Group className="mb-3 position-relative" controlId="password">
                        <Form.Control
                          type={showPassword != true ? "password":"text"}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                          placeholder="Password"
                        />
                        {showPassword != true ? <Icon.EyeSlash onClick={()=>{setShowPassword(true)}} className="cursor-pointer eye-password"/>:<Icon.EyeFill  onClick={()=>{setShowPassword(false)}} className="cursor-pointer eye-password-active"/>}    
                      </Form.Group>
                    )}
                    
                    {loginOption === 1 ? (
                      <span className="mb-4 text-left nav-link talic-forgot" onClick={()=>{window.location.href='https://lp.tataaia.com/ResetP/'}}>Forgot Password</span>
                    ) : (
                      <div style={{textAlign: "left"}} className="mb-3">
                        <span 
                          style={{textAlign: "left"}}    
                          className="text-muted small cursor-pointer"
                          onClick={() => {
                            if (loginOption === 3) {
                              if (otpStatus) {
                                resendOTPHandler();
                              } else if (saveUsername) {
                                // If we have a saved username but not in OTP status yet
                                resendOTPHandler();
                              }
                            }
                          }}
                        >
                          {loginOption === 3 ? "Resend OTP" : ""}
                        </span>
                      </div>
                    )}
                    
                    <Button className="position-relative" variant="info" type="submit" style={{ width: "100%" }}>
                      {buttonLoader ? <div className="login-dual-ring"></div> : 'Submit'}
                    </Button>
                    
                    {company_logo?.privacyPolicyPageUrl && <p className="privacy-policy">On logging in, you agree with our <a href={company_logo?.privacyPolicyPageUrl} target="blank" className="privacy-policy text-primary mt-2 mb-0">Privacy Policy</a></p>}
                  </Form>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default Login;
