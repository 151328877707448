/* eslint-disable */
import { useRef, useState, useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Form, Card, Button, Modal, FormGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import _ from "lodash";
import FileInputComponent from "react-file-input-previews-base64";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Toast from "../../../shared/Toast";

import "./globalfield.css"
//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  //SetLoaderStatus,
  fetchAssetSelectTypeList,
  fetchAssetLibraryCategoryList,
  fetchAssetLibrarySubCategoryList,
  fetchAssetLibraryHashtagList,
  fetchAssetLibraryLeadGenerationList,
  fetchCampaignList,
  fetchGreetingList,
  fetchLandingpageUrls,
  fetchShowcaseUrls,
  fetchWhatsapppageUrls,
  fetchMicrositeUrls,
  setBaseUploadData,
  setBreadcrumbStatus,
  setGlobalFieldsData,
  setSelectedCategories,
  setSelectedCampaigns,
  setSelectedHashtags,
  setSelectedGreetings,
  setSelectedPersonalizes,
  setSelectedContentType,
  setSelectedBusinessType,
  setSelectedVideoType,
  fetchContentTypeList,
  setSelectedDefineUrl,
  setCustomUrl,
  fetchPersonalizeList,
  setSelectedSubCategories,
  addNewTypePersonalize,
  deleteNewTypePersonalize,
  addNewHashTag
} from "../../../../redux/actions/actions";
import { fetchAssetLibraryVideoTypeList } from "../../../../redux/actions/conversionAction";
import {
  fetchBusinessNeed
} from "../../../../redux/actions/setupActions";

const GlobalFields = (props) => {
  const authToken = props.authToken;

  let navigate = useNavigate();

  const nameRef = useRef();
  const longCaptionRef = useRef();
  const shortCaptionRef = useRef();
  const cusRef = useRef();
  const msRef = useRef();
  const descRef = useRef();

  // const [title, setTitle] = useState("");
  const fileInputRef = useRef(null);
  const [platformFB, setPlatformFB] = useState(false);
  const [buyNowCheck, setBuyNowCheck] = useState(false);
  const [personalizeModal, setPersonalizeModal] = useState(false);
  const [personalizeDeleteModal, setPersonalizeDeleteModal] = useState(false);
  const [personalizeName, setPersonalizeName] = useState("");
  const [platformTW, setPlatformTW] = useState(false);
  const [platformLN, setPlatformLN] = useState(false);
  const [platform, setPlatform] = useState([]);
  const [urlOption, setUrlOption] = useState("N");
  const [lpUrl, setLpUrl] = useState(false);
  const [scUrl, setScUrl] = useState(false);
  const [ctaCheck, setCtaCheck] = useState(false);
  const [waUrl, setWaUrl] = useState(false);
  const [personalizeType, setPersonalizeType] = useState("");
  const [msUrl, setMsUrl] = useState(false);
  const [ctaType, setCtaType] = useState(null);
  const [cusUrl, setCusUrl] = useState(false);
  const [noneUrl, setNoneUrl] = useState(true);
  const [longCaption, setLongCaption] = useState("");
  const [selectedPersonalize, setSelectedPersonalize] = useState(null);
  const [shortCaption, setShortCaption] = useState("");
  const [descCaption, setDescCaption] = useState("");
  const [personalizeFileData, setPersonalizeFileData] = useState(null);
  const [personalizeFileName, setPersonalizeFileName] = useState("");
  const [selectedPersonalizeCheck, setSelectedPersonalizeCheck] = useState([]);
  const [formType, setFormType] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [audioName, setAudioName] = useState("");
  //const [secureUrl, setSecureUrl] = useState(null);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedSubCategoryList, setSelectedSubCategoryList] = useState([]);
  const [selectedHashtagList, setSelectedHashtagList] = useState([]);
  const [selectedCampaignList, setSelectedCampaignList] = useState([]);
  const [selectedGreeting, setSelectedGreeting] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [leadGeneration, setLeadGeneration] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedHashtag, setSelectedHashtag] = useState([]);

  const [defineUrl, setDefineUrl] = useState(null);
  const [secureUrl, setSecureUrl] = useState(null);
  const [custUrl, setCustUrl] = useState(null);

  const [selectedType, setSelectedType] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [videoType, setVideoType] = useState(null);
  const [whatsappType, setWhatsappType] = useState(null);
  const [addMoreData, setAddMoreData] = useState({});
  const [show, setShow] = useState(false);


  const [tagName, setTagName] = useState("");
  const [tagModal, setTagModal] = useState(false);


  const handleClose = () => {
    setShow(false);
    setAddMoreData({});
  };
  const handleShow = () => setShow(true);
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);
  const isLoading = useSelector((state) => state.assetUpload.loading);

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const baseUploadData = useSelector(
    (state) => state.assetUpload.baseUploadData
  );

  const cta_asset_list = useSelector(
    (state) => state.conversionData.cta_asset_list
  );
  
  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );

  const assetLibrarySubCategoryList = useSelector(
    (state) => state.assetLibrary.subcategoryList
  );

  const assetLibraryLeadGenerationList = useSelector(
    (state) => state.assetLibrary.leadGenerationList
  );

  const business_need_list = useSelector((state) => state.setupScreen.business_need_list);

  const assetLibraryHashtagList = useSelector(
    (state) => state.assetLibrary.hashtagList
  );

  //const campaignList = useSelector((state) => state.assetUpload.campaignList);

  const greetingList = useSelector((state) => state.assetUpload.greetingList);

  const landingpageUrlList = useSelector(
    (state) => state.assetUpload.landingpageUrlList
  );

  const showcaseUrlList = useSelector(
    (state) => state.assetUpload.showcaseUrlList
  );
  const business_flag = useSelector((state) => state.setupScreen.business_flag);
  const video_cta_flag = useSelector((state) => state.conversionData.video_cta_flag);
  const micrositeUrl = useSelector((state) => state.assetUpload.micrositeUrl);
  const personalizeList = useSelector((state) => state.assetUpload.personalizeList);
  const whatsapppageUrlList = useSelector((state) => state.assetUpload.whatsapppageUrlList);
  const landingSecureUrl = useSelector((state) => state.assetUpload.landingSecureUrl);
  const showcaseSecureUrl = useSelector((state) => state.assetUpload.showcaseSecureUrl);

  const contentTypeList = useSelector(
    (state) => state.assetUpload.contentTypeList
  );

  let categories = [];
  if (assetLibraryCategoryList && assetLibraryCategoryList.length > 0) {
    categories = assetLibraryCategoryList.map(
      ({ id: value, solution_type: label }) => ({
        value,
        label,
      })
    );
  }

  let subcategories = [];
  if (assetLibrarySubCategoryList && assetLibrarySubCategoryList.length > 0) {
    subcategories = assetLibrarySubCategoryList.map(
      ({ id: value, it_subcat: label }) => ({
        value,
        label,
      })
    );
  }

  let hashtags = [];
  if (assetLibraryHashtagList && assetLibraryHashtagList.length > 0) {
    hashtags = assetLibraryHashtagList.map(
      ({ id: value, tag_name: label }) => ({
        value,
        label,
      })
    );
  }

  // const camapigns = campaignList.map(({ c_id: value, camp_name: label }) => ({
  //   value,
  //   label,
  // }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(authToken));
    dispatch(fetchAssetLibraryCategoryList(authToken));
    dispatch(fetchAssetLibrarySubCategoryList(authToken));
    dispatch(fetchAssetLibraryLeadGenerationList(authToken));
    dispatch(fetchAssetLibraryHashtagList(authToken));
    dispatch(fetchAssetLibraryVideoTypeList(authToken));
    //dispatch(fetchCampaignList(authToken));
    dispatch(fetchGreetingList(authToken));
    dispatch(fetchLandingpageUrls(authToken));
    dispatch(fetchWhatsapppageUrls(authToken));
    dispatch(fetchShowcaseUrls(authToken));
    dispatch(fetchMicrositeUrls(authToken));
    dispatch(fetchContentTypeList(authToken));
    dispatch(fetchBusinessNeed(authToken));
    dispatch(fetchPersonalizeList(authToken));
    if (_.isEmpty(selectedAssetType)) {
      Toast("Uploaded content lost, please re-upload..", "E");
      navigate("/new-asset");
    }
  }, [authToken, dispatch]);

  props.pageTypeHandler("Global Fields");

  const selectCategoryHandler = (value, event) => {
    if (event === "select-option" && event === "*") {
      setSelectedCategoryList(categories);
    } else if (
      event === "deselect-option" &&
      event === "*"
    ) {
      setSelectedCategoryList([]);
    } else if (event.action === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedCategoryList(value.map(({ value }) => value));
    } else if (value.length === categories.length - 1) {
      setSelectedCategoryList(categories);
    } else {
      setSelectedCategoryList(value.map(({ value }) => value));
    }
  };

  const selectSubCategoryHandler = (value, event) => {
    if (event === "select-option" && event === "*") {
      setSelectedCategoryList(subcategories);
    } else if (
      event === "deselect-option" &&
      event === "*"
    ) {
      setSelectedSubCategoryList([]);
    } else if (event.action === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedSubCategoryList(value.map(({ value }) => value));
    } else if (value.length === subcategories.length - 1) {
      setSelectedSubCategoryList(subcategories);
    } else {
      setSelectedSubCategoryList(value.map(({ value }) => value));
    }
  };

  const selectHashtagHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedHashtagList(hashtags);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedHashtagList([]);
    } else if (event.action === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedHashtagList(value.map(({ value }) => value));
    } else if (value.length === hashtags.length - 1) {
      setSelectedHashtagList(hashtags);
    } else {
      setSelectedHashtagList(value.map(({ value }) => value));
    }
  };

  // const selectCampaignHandler = (value, event) => {
  //   if (event.action === "select-option" && event.option.value === "*") {
  //     setSelectedCampaignList(camapigns);
  //   } else if (
  //     event.action === "deselect-option" &&
  //     event.option.value === "*"
  //   ) {
  //     setSelectedCampaignList([]);
  //   } else if (event.action === "deselect-option") {
  //     value.filter((o) => o.value !== "*");
  //     setSelectedCampaignList(value.map(({ value }) => value));
  //   } else if (value.length === camapigns.length - 1) {
  //     setSelectedCampaignList(camapigns);
  //   } else {
  //     setSelectedCampaignList(value.map(({ value }) => value));
  //   }
  // };

  const platformHandler = (e, type) => {
    let _updatedContent = _.clone(platform);
    if (e.target.checked) {
      if (type === "fb") {
        _updatedContent.push(1);
      } else if (type === "tw") {
        _updatedContent.push(2);
      } else if (type === "in") {
        _updatedContent.push(3);
      }
    } else {
      if (type === "fb") {
        _updatedContent = _updatedContent.filter((item) => item !== 1);
      } else if (type === "tw") {
        _updatedContent = _updatedContent.filter((item) => item !== 2);
      } else if (type === "in") {
        _updatedContent = _updatedContent.filter((item) => item !== 3);
      }
    }
    setPlatform(_updatedContent);
  };
  
  const handlePersonalizeThumbnail = (e, count, type) => {
    if (e) {
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
            let data = {
              base64: fr.result,
              file: {
                name: e.target.files[0].name,
                size: e.target.files[0].size,
                type: e.target.files[0].type,
              },
            }
            setPersonalizeFileData(data);
            setPersonalizeFileName(e.target.files[0].name);
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }
  
  const handleUrlCheckboxChange = (e,obj) => {
      if (selectedPersonalizeCheck.includes(obj)) {
        setSelectedPersonalizeCheck(selectedPersonalizeCheck.filter(item => item !== obj));
      } else {
        setSelectedPersonalizeCheck([...selectedPersonalizeCheck, obj]);
      }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (urlOption === "M" && msRef.current.value) {
      setSecureUrl(micrositeUrl);
      setDefineUrl(micrositeUrl);
    } else if (urlOption === "C" && cusRef.current.value) {
      setSecureUrl(cusRef.current.value);
      setDefineUrl(cusRef.current.value);
      setCustUrl(cusRef.current.value);
    } else if (urlOption === "N") {
      setSecureUrl("");
      setDefineUrl("");
    }

    let globalFields;

    if (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") {
      globalFields = baseUploadData.data[0].map((baseData) => ({
        ...baseData,
        Base: {
          ...baseData.Base,
          content_name: selectedAssetType.article_type,
          post_type: 1,
          buy_now: buyNowCheck !== true ? 0 : 1,
          article_id: selectedAssetType.id, // article_id
          business_need: business_flag === true ? parseInt(businessType) : "",
          platform: platform, // array[1,2]
          status: "Draft", // default value Draft
          title: nameRef.current.value,
          multi_cateSolutions: selectedCategoryList,
          sub_category: selectedSubCategoryList,
          lg_type: leadGeneration + "",
          whatsapp_id: urlOption === "W" ? whatsappType : "",
          //campaign: selectedCampaignList,
          intrltags: selectedHashtagList,
          long_caption: (longCaption === "") ? "" : longCaptionRef.current.value,
          short_caption: ((!platformTW && !platformLN) ? "" : ((platformTW || platformLN) && shortCaption === "") ? "" : shortCaptionRef.current.value),
          secure_url: secureUrl, /// Secure URL - hidden - if none-empty //LP & SC only
          url_option: urlOption,
          greeting_id: selectedGreeting,
        },
      }));
    } else if (
      selectedAssetType.type === "Brochure" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic"
    ) {
      globalFields = baseUploadData.data[0].map((baseData) => ({
        ...baseData,
        Base: {
          ...baseData.Base,
          buy_now: buyNowCheck !== true ? 0 : 1,
          content_name: selectedAssetType.article_type,
          article_id: selectedAssetType.id,
          content_type: parseInt(selectedType),
          business_need: business_flag === true ? parseInt(businessType) : "",
          //platform: platform,
          status: "Draft",
          whatsapp_id: urlOption === "W" ? whatsappType : "",
          doc_type: 2,
          title: nameRef.current.value,
          multi_cateSolutions: selectedCategoryList,
          sub_category: selectedSubCategoryList,
          //campaign: selectedCampaignList,
          intrltags: selectedHashtagList,
          description: (descCaption === "") ? "" : descRef.current.value,
          url_option: urlOption,
        },
      }));
    } else if (selectedAssetType.type === "Video") {
      globalFields = baseUploadData?.data[0].map((baseData) => ({
        ...baseData,
        Base: {
          ...baseData.Base,
          buy_now: buyNowCheck !== true ? 0 : 1,
          content_name: selectedAssetType.article_type,
          post_type: 1,
          article_id: selectedAssetType.id, // article_id
          platform: platform, // array[1,2]
          status: "Draft", // default value Draft
          title: nameRef.current.value,
          personalize_id: company_logo?.personalize_video === 1 ? selectedPersonalize : "",
          personalize_template: company_logo?.personalize_video === 1 ? personalizeFileData : "",
          p_type: personalizeType,
          whatsapp_id: urlOption === "W" ? whatsappType : "",
          reel: personalizeType?.includes("reel") ? audioFile : "",
          audio_duration: audioDuration != null ? audioDuration : null,
          video_type: video_cta_flag === true ? parseInt(videoType) : null,
          form_type: video_cta_flag === true && formType !== null ? formType : null,
          business_need: business_flag === true ? parseInt(businessType) : "",
          multi_cateSolutions: selectedCategoryList,
          sub_category: selectedSubCategoryList,
          lg_type: leadGeneration + "",
          //campaign: selectedCampaignList,
          intrltags: selectedHashtagList,
          description: (descCaption === "") ? "" : descRef.current.value,
          secure_url: secureUrl, /// Secure URL - hidden - if none-empty //LP & SC only
          url_option: urlOption,
          greeting_id: selectedGreeting,
        },
      }));
    } else if (selectedAssetType.type === "Banner") {
      globalFields = baseUploadData.data[0].map((baseData) => ({
        ...baseData,
        Base: {
          ...baseData.Base,
          content_name: selectedAssetType.article_type,
          // post_type: 1,
          buy_now: buyNowCheck !== true ? 0 : 1,
          article_id: selectedAssetType.id, // article_id
          business_need: business_flag === true ? parseInt(businessType) : "",
          //platform: platform, // array[1,2]
          status: "Draft", // default value Draft
          whatsapp_id: urlOption === "W" ? whatsappType : "",
          title: nameRef.current.value,
          //multi_cateSolutions: selectedCategoryList,
          //campaign: selectedCampaignList,
          //intrltags: selectedHashtagList,
          description: (descCaption === "") ? "" : descRef.current.value,
          secure_url: secureUrl, /// Secure URL - hidden - if none-empty //LP & SC only
          url_option: urlOption,
          //greeting_id: selectedGreeting,
        },
      }));
    }

    let cat = [];
    let scat = [];
    let camp = [];
    let hash = [];
    let newCategory = selectedCategory;
    let newSubCategory = selectedSubCategory;
    let newCampaign = selectedCampaign;
    let newHashtag = selectedHashtag;
    // let newDefineUrl = selectedDefineUrl;

    if (_.has(globalFields[0], "Base.multi_cateSolutions")) {
      globalFields[0].Base.multi_cateSolutions.map((d) => {
        return categories.map((c) => {
          if (c.value === d) {
            cat.push(c);
            //setSelectedCategory(cat);
            newCategory = cat;
            return "";
          } else return "";
        });
      });
    }

    if (_.has(globalFields[0], "Base.sub_category")) {
      globalFields[0].Base.sub_category.map((d) => {
        return subcategories.map((c) => {
          if (c.value === d) {
            scat.push(c);
            //setSelectedCategory(cat);
            newSubCategory = scat;
            return "";
          } else return "";
        });
      });
    }

    // if (_.has(globalFields[0], "Base.campaign")) {
    //   globalFields[0].Base.campaign.map((d) => {
    //     return camapigns.map((c) => {
    //       if (c.value === d) {
    //         camp.push(c);
    //         //setSelectedCampaign(camp);
    //         newCampaign = camp;
    //         return "";
    //       } else return "";
    //     });
    //   });
    // }

    if (_.has(globalFields[0], "Base.intrltags")) {
      globalFields[0].Base.intrltags.map((d) => {
        return hashtags.map((c) => {
          if (c.value === d) {
            hash.push(c);
            //setSelectedHashtag(hash);
            newHashtag = hash;
            return "";
          } else return "";
        });
      });
    }

    // console.log("globalFields",globalFields);
    // console.log("selectedCategories",newCategory);
    // console.log("selectedCampaigns",newCampaign);
    console.log("selectedHashtags", newHashtag);
    // console.log("selectedDefineUrl",defineUrl);
    console.log("globalFields", globalFields);
    // console.log("selected subcat", selectedSubCategory);
    // console.log("selected newSubcat", newSubCategory);
    if (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
      selectedAssetType.type === "Social") {

      if (globalFields[0].Base.title &&
        (globalFields[0].Base.long_caption || globalFields[0].Base.short_caption)) {

        dispatch(setGlobalFieldsData(globalFields, selectedCategory, selectedSubCategory, selectedCampaign, selectedHashtag));
        dispatch(setSelectedCategories(newCategory));
        dispatch(setSelectedSubCategories(newSubCategory));
        //dispatch(setSelectedCampaigns(newCampaign));
        dispatch(setSelectedHashtags(newHashtag));
        dispatch(setSelectedGreetings(selectedGreeting));
        dispatch(setSelectedContentType(selectedType));
        dispatch(setSelectedBusinessType(businessType));
        dispatch(setSelectedDefineUrl(defineUrl));
        dispatch(setCustomUrl(custUrl));
        dispatch(setBreadcrumbStatus(3));
        setTimeout(() => {
          navigate("/new-asset/base-asset-details");
        }, 2000);

      } else {
        Toast("Please enter mandatory fields.", "E");
      }
    } else if (selectedAssetType.type === "Brochure" ||
      selectedAssetType.type === "Document" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic" ||
      selectedAssetType.type === "Video") {

      if (globalFields[0].Base.title) {

        dispatch(setGlobalFieldsData(globalFields, selectedCategory, selectedSubCategory, selectedCampaign, selectedHashtag));
        dispatch(setSelectedCategories(newCategory));
        dispatch(setSelectedSubCategories(newSubCategory));
        //dispatch(setSelectedCampaigns(newCampaign));
        dispatch(setSelectedHashtags(newHashtag));
        dispatch(setSelectedPersonalizes(selectedPersonalize));
        dispatch(setSelectedGreetings(selectedGreeting));
        dispatch(setSelectedContentType(selectedType));
        dispatch(setSelectedBusinessType(businessType));
        dispatch(setSelectedVideoType(videoType));
        dispatch(setSelectedDefineUrl(defineUrl));
        dispatch(setCustomUrl(custUrl));
        dispatch(setBreadcrumbStatus(3));
        setTimeout(() => {
          navigate("/new-asset/base-asset-details");
        }, 2000);

      } else {
        Toast("Please enter mandatory fields.", "E");
      }
    } else if (selectedAssetType.type === "Banner" ||
      selectedAssetType.type === "Banner") {

      dispatch(setGlobalFieldsData(globalFields));
      dispatch(setSelectedDefineUrl(defineUrl));
      dispatch(setCustomUrl(custUrl));
      dispatch(setBreadcrumbStatus(3));
      setTimeout(() => {
        navigate("/new-asset/base-asset-details");
      }, 2000);
    }

    // if(globalFields[0].Base.multi_cateSolutions && 
    //    globalFields[0].Base.multi_cateSolutions.length > 0 &&
    //    globalFields[0].Base.platform.length > 0) {

    //     dispatch(setGlobalFieldsData(globalFields, selectedCategory, selectedCampaign, selectedHashtag));
    //     dispatch(setSelectedCategories(newCategory));
    //     dispatch(setSelectedCampaigns(newCampaign));
    //     dispatch(setSelectedHashtags(newHashtag));
    //     dispatch(setSelectedGreetings(selectedGreeting));
    //     dispatch(setSelectedContentType(selectedType));
    //     dispatch(setSelectedDefineUrl(defineUrl));
    //     dispatch(setCustomUrl(custUrl));
    //     dispatch(setBreadcrumbStatus(3));
    //     setTimeout(() => {
    //       navigate("/new-asset/base-asset-details");
    //     }, 2000);

    //   } else if (globalFields[0].Base.content_name === "Banners") {

    //     dispatch(setGlobalFieldsData(globalFields));
    //     dispatch(setSelectedDefineUrl(defineUrl));
    //     dispatch(setCustomUrl(custUrl));
    //     dispatch(setBreadcrumbStatus(3));
    //     setTimeout(() => {
    //       navigate("/new-asset/base-asset-details");
    //     }, 2000);

    //   } else {
    //     Toast("Please enter mandatory fields.", "E");
    //   }

  };


  const addMoreHandler = () => {
    let addData;

    if (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") {
      addData = addMoreData.map((data) => {
        return {
          Base: {
            thumbImg: {},
            post_image: {
              base64: data.base64,
              file: {
                name: data.name,
                size: data.size,
                type: data.type,
              },
            },
          },
        };
      });
    } else if (
      selectedAssetType.type === "Brochure" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic"
    ) {
      addData = addMoreData.map((data) => {
        return {
          Base: {
            thumbImg: {},
            document: {
              base64: data.base64,
              file: {
                name: data.name,
                size: data.size,
                type: data.type,
              },
            },
          },
        };
      });
    } else if (selectedAssetType.type === "Video") {
      addData = addMoreData.map((data) => {
        return {
          Base: {
            thumbImg: {},
            video: {
              base64: data.base64,
              file: {
                name: data.name,
                size: data.size,
                type: data.type,
              },
            },
          },
        };
      });
    } else if (selectedAssetType.type === "Banner") {
      addData = addMoreData.map((data) => {
        return {
          Base: {
            thumbImg: {},
            banner_image: {
              base64: data.base64,
              file: {
                name: data.name,
                size: data.size,
                type: data.type,
              },
            },
          },
        };
      });
    }

    let newBaseUploadData = {
      data: [[...baseUploadData.data[0], ...addData]],
    };
    dispatch(setBaseUploadData(newBaseUploadData));
    handleClose();
  };

  const onSelectCategory = (selectedList, selectedItem) => {
    selectCategoryHandler(selectedList, "select-option");
    setSelectedCategory(selectedList);
  };

  const onRemoveCategory = (selectedList, removedItem) => {
    selectCategoryHandler(selectedList, "deselect-option");
    setSelectedCategory(selectedList);
  };


  const onSelectSubCategory = (selectedList, selectedItem) => {
    selectSubCategoryHandler(selectedList, "select-option");
    setSelectedSubCategory(selectedList);
  };

  const onRemoveSubCategory = (selectedList, removedItem) => {
    selectSubCategoryHandler(selectedList, "deselect-option");
    setSelectedSubCategory(selectedList);
  };

  // const onSelectCampaign = (selectedList, selectedItem) => {
  //   selectCampaignHandler(selectedList, "select-option");
  //   setSelectedCampaign(selectedList);
  // };

  // const onRemoveCampaign = (selectedList, removedItem) => {
  //   selectCampaignHandler(selectedList, "deselect-option");
  //   setSelectedCampaign(selectedList);
  // };

  const onSelectHashtag = (selectedList, selectedItem) => {
    selectHashtagHandler(selectedList, "select-option");
    setSelectedHashtag(selectedList);
  };

  const onRemoveHashtag = (selectedList, removedItem) => {
    selectHashtagHandler(selectedList, "deselect-option");
    setSelectedHashtag(selectedList);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fr = new FileReader();
      
      fr.onload = function () {
        const audio = document.createElement('audio');
        audio.src = fr.result;
        audio.onloadedmetadata = function () {
          if (audio.duration > 5.5) {
            Toast("The audio file should be upto 5 seconds.", "E");
            fileInputRef.current.value = '';
            setAudioFile(null);
            setAudioName(null);
            setAudioDuration(null);
          } else {
            // setError(null);
            const data = {
              base64: fr.result,
              file: {
                name: file.name,
                size: file.size,
                type: file.type,
              },
            };
            console.log("Audio data:", data);
            setAudioDuration(audio?.duration);
            setAudioFile(data);
            setAudioName(file.name);
          }
        };

        audio.onerror = function () {
          // setError('Failed to load audio metadata');
          setAudioFile(null);
          setAudioName(null);
        };
      };

      fr.onerror = function () {
        // setError('Failed to read file');
        setAudioFile(null);
        setAudioName(null);
      };

      fr.readAsDataURL(file);
    } else {
      // setError('No file selected');
      setAudioFile(null);
      setAudioName(null);
    }
  };
  
  useEffect(() => {
    if (assetLibraryHashtagList.length > 0 && tagName) {
      let t = assetLibraryHashtagList.filter(e => {
        return e.tag_name === tagName
      });
      //console.log("text", t);
      let htags = t.map(
        ({ id: value, tag_name: label }) => ({
          value,
          label,
        })
      );

      if (!_.isEmpty(htags)) {
        setSelectedHashtag([...selectedHashtag, ...htags]);
        if (!_.isEmpty(selectedHashtagList)) {
          let arr = [...selectedHashtagList];
          arr.push(htags[0]?.value);
          setSelectedHashtagList(arr);
        } else {
          let arr = [];
          arr.push(htags[0]?.value);
          setSelectedHashtagList(arr);
        }
      }
    }
  }, [assetLibraryHashtagList])

  const addHashTag = () => {
    if (tagName !== "") {
      dispatch(addNewHashTag(authToken, tagName));
      setTagModal(false);
    } else {
      Toast("Please enter tag name", "E");
    }
  }
  
  const addPersonalizeName = () => {
    if (personalizeName !== "") {
      dispatch(addNewTypePersonalize(authToken, personalizeName));
      setPersonalizeModal(false);
    } else {
      Toast("Please enter type name", "E");
    }
  }
  
  const deletePersonalizeName = () => {
    if (selectedPersonalizeCheck?.length > 0) {
      selectedPersonalizeCheck.map((key) => {
        dispatch(deleteNewTypePersonalize(authToken, key.id));
      })
      setSelectedPersonalizeCheck([]);
      setPersonalizeDeleteModal(false);
    } else {
      Toast("Please select type name", "E");
    }
  }

  console.log("hashtag", selectedHashtag);
  console.log("selectedhlist", selectedHashtagList);

  return (
    <div className="new-asset  create-content">
      {isLoading && <Loading />}
      <Row className="custom-row">
        <Col>
          <ul
            className={
              !selectedAssetType.type === "Brochure" ||
                !selectedAssetType.type === "Infographics" ||
                !selectedAssetType.type === "Infographic"
                ? "selected-asset-list"
                : "selected-asset-list brochure"
            }
          >
            <li className="asset-type-img">
              <img
                src={selectedAssetType.article_image}
                alt={selectedAssetType.article_type}
              />
            </li>
            <li className="web-icon active">
              <span>
                <Icon.Globe />
              </span>
            </li>
            {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
              <>
                {baseUploadData &&
                  baseUploadData.data.map((d) => {
                    return d.map((b, index) => {
                      return (
                        <li className="asset">
                          <img
                            src={b.Base.post_image.base64}
                            alt={b.Base.post_image.file.name}
                          />
                        </li>
                      );
                    });
                  })}
              </>
            )}
            {selectedAssetType.type === "Banner" && (
              <>
                {baseUploadData &&
                  baseUploadData.data.map((d) => {
                    return d.map((b, index) => {
                      return (
                        <li className="asset">
                          <img
                            src={b.Base.banner_image.base64}
                            alt={b.Base.banner_image.file.name}
                          />
                        </li>
                      );
                    });
                  })}
              </>
            )}
            {(selectedAssetType.type === "Brochure" ||
              selectedAssetType.type === "Infographics" ||
              selectedAssetType.type === "Infographic") && (
                <>
                  {baseUploadData.data &&
                    baseUploadData.data.map((d) => {
                      return d.map((b, index) => {
                        return (
                          <li
                            className="asset file"
                            title={b.Base.document.file.name}
                          >
                            <span className="file-type-icon">
                              <Icon.FilePdf />
                            </span>
                            <span className="file-type-name">
                              {b.Base.document.file.name}
                            </span>
                          </li>
                        );
                      });
                    })}
                </>
              )}
            {selectedAssetType.type === "Video" && (
              <div className="position-relative">
                {baseUploadData.data &&
                  baseUploadData.data.map((d) => {
                    return d.map((b, index) => {
                      if (_.has(b.Base, "video_url")) {
                        return (
                          <li className="asset file" title={b.Base.video_url}>
                            <span className="file-type-icon">
                              <Icon.FilePlay />
                            </span>
                            <span className="file-type-name">
                              {b.Base.video_url}
                            </span>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            className="asset file"
                            title={b.Base.video.file.name}
                          >
                            <span className="file-type-icon">
                              <Icon.FilePlay />
                            </span>
                            <span className="file-type-name">
                              {b.Base.video.file.name}
                            </span>
                          </li>
                        );
                      }
                    });
                  })}
              </div>
            )}
            {(selectedAssetType.type != "Video") && <li className="add-more" onClick={handleShow}>
              +
            </li>}
          </ul>
        </Col>
      </Row>
      <Card className="global-fields-card m-lg-0 mx-2">
        <Row className="asset-type g-0 p-0">
          <Col className="col-lg-6 col-md-12">
            <h1>Global Fields</h1>
            <h6 className="pe-lg-5 pe-md-5">It is a central location where each field value and description gets automatically assigned to all the content types. You can edit the value and description for each content by manually selecting them.</h6>
          </Col>
          <Col className="col-lg-6 col-md-12">
            <Row className="custom-row">
              <Col>
                <Form onSubmit={submitHandler} className="my-4 mb-2">
                  <div className="smartFolder-multiSelect">
                    {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      !selectedAssetType.type === "Brochure" ||
                      !selectedAssetType.type === "Infographics" ||
                      selectedAssetType.type === "Video" ||
                      !selectedAssetType.type === "Banner") && <Form.Label htmlFor="select-platform">
                        Select Platform
                      </Form.Label>}
                    <div className="url-max-size">
                      {selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                        !selectedAssetType.type === "Brochure" ||
                        !selectedAssetType.type === "Infographics" ||
                        selectedAssetType.type === "Video" ||
                        !selectedAssetType.type === "Banner" ? (
                        <>


                          <Form.Group className="mb-4 checkbox-btn">
                            <Form.Check
                              type="checkbox"
                              label="Facebook"
                              name="fb-platform"
                              checked={platformFB}
                              onChange={(e) => {
                                platformHandler(e, "fb");
                                setPlatformFB(!platformFB);
                              }}
                            />
                          </Form.Group>



                        </>
                      ) : (
                        ""
                      )}

                      {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                        !selectedAssetType.type === "Brochure" ||
                        !selectedAssetType.type === "Infographics" ||
                        !selectedAssetType.type === "Infographic" ||
                        !selectedAssetType.type === "Video") && (
                          <>



                            <Form.Group className="mb-4 checkbox-btn">
                              <Form.Check
                                type="checkbox"
                                label="Twitter"
                                name="tw-platform"
                                onChange={(e) => {
                                  platformHandler(e, "tw");
                                  setPlatformTW(!platformTW);
                                }}
                              />
                            </Form.Group>

                            <Form.Group className="mb-4 checkbox-btn">
                              <Form.Check
                                type="checkbox"
                                label="LinkedIn"
                                name="in-platform"
                                onChange={(e) => {
                                  platformHandler(e, "in");
                                  setPlatformLN(!platformLN);
                                }}
                              />
                            </Form.Group>
                          </>
                        )}
                    </div>
                  </div>
                  <Form.Group className="mb-3 smartFolder-multiSelect">
                    <Form.Label className="mt-2">
                      Name <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control className="input-form"
                      type="text"
                      ref={nameRef}
                      autoComplete="off"
                      required
                      placeholder="Name"
                    />
                  </Form.Group>

                  {selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Brochure" ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic" ||
                    selectedAssetType.type === "Video" ||
                    !selectedAssetType.type === "Banner" ? (
                    <>
                      {categories && (
                        <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mt-2">
                            Categories
                          </Form.Label>
                          
                          <Multiselect className="input-form"
                            options={categories}
                            allowSelectAll={true}
                            selectedValues={selectedCategory}
                            onSelect={onSelectCategory}
                            onRemove={onRemoveCategory}
                            displayValue="label"
                            showCheckbox={true}
                            customArrow={<Icon.ChevronDown/>}  
                            avoidHighlightFirstOption={true}
                            placeholder="Select Categories"
                            singleSelect={company_logo?.new_login != "tal" ? false : true}  
                          />

                        </div>
                      )}

                      {/* {camapigns && (
                        <div className="smartFolder-multiSelect mb-4">
                          <Form.Label className="mt-2">
                            Campaigns
                          </Form.Label>
                          <Multiselect
                            options={camapigns}
                            selectedValues={selectedCampaign}
                            onSelect={onSelectCampaign}
                            onRemove={onRemoveCampaign}
                            displayValue="label"
                            showCheckbox={true}
                            placeholder="Select Campaigns"
                          />
                        </div>
                      )} */}
                      {selectedAssetType.type === "Video" && company_logo?.personalize_video === 1 && <>
                        <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mt-1">
                              Personalize Video
                            </Form.Label>
                          <div className="input-form-column">
                            <Form.Select
                              className="greeting input-form w-100 p-2 float-unset text-capitalize"
                              aria-label="select-greetings"
                              style={{color:"#6e6b6b"}}
                              onChange={(e) => {
                                setSelectedPersonalize(e.target.value);
                                setPersonalizeType(e.target?.selectedOptions[0]?.text?.toLowerCase())
                              }}
                              value={selectedPersonalize}
                            >
                              <option value="null">Select Personalize Video</option>
                              {personalizeList?.preVideoList?.length > 0 &&
                                personalizeList?.preVideoList?.map((personalise) => {
                                  return (
                                    <>
                                      <option className="text-capitalize" onClick={() => { console.log("greeting_name",personalise?.greeting_name)}} value={personalise.id}>
                                        {personalise.greeting_name}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                            <div className="max-width-100">
                                <a className="mt-1 text-decoration-none show-pointer" onClick={() => { setPersonalizeModal(true) }}>Add New Type?</a>
                                {personalizeList?.preVideoList.length > 0 && <span style={{float:"right"}} onClick={() => { setPersonalizeDeleteModal(true) }} className="delete-icon"><Icon.Trash/></span>}
                            </div>
                          </div>
                        </div>
                      
                        {(!personalizeType?.includes("reel") && selectedPersonalize !== null && selectedPersonalize!== "") && <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mt-0">
                              Co-Branded Template {selectedPersonalize?.greeting_name}
                            </Form.Label>
                          <div className="input-form-column d-flex">
                              <input className="d-none" type="file" id="actual-btn" accept="image/*" onChange={(e)=>{handlePersonalizeThumbnail(e)}} />
                              <label for="actual-btn"><Icon.Upload style={{ marginRight: "15px", fontSize: "18px" }} /><span className="pl-3">{personalizeFileName}</span></label> 
                          </div>
                        </div>}
                          
                        {(selectedPersonalize !== null && selectedPersonalize!== "" && personalizeType?.includes("reel")) && <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mt-0">
                              Upload Audio
                            </Form.Label>
                          <div className="input-form-column d-flex">
                              <input ref={fileInputRef} className="d-none" type="file" id="actual-audio-btn" accept="audio/*" onChange={handleFileChange} />
                              <label htmlFor="actual-audio-btn"><Icon.Upload style={{ marginRight: "15px", fontSize: "18px" }} /><span className="pl-3">{audioName}</span></label> 
                          </div>
                        </div>}  
                          </>
                      } 
                        
                      
                      {hashtags && (
                        <div className="smartFolder-multiSelect mb-3">
                          <Form.Label className="mb-3">
                            Hashtags
                          </Form.Label>
                          <div className="input-form-column">
                            <Multiselect className="input-form w-100"
                              options={hashtags}
                              avoidHighlightFirstOption={true}
                              selectedValues={selectedHashtag}
                              onSelect={onSelectHashtag}
                              onRemove={onRemoveHashtag}
                              displayValue="label"
                              showCheckbox={true}
                              placeholder="Select Hashtags"
                            />
                            <div className="max-width-100">

                              <a className="mt-1 text-decoration-none show-pointer" onClick={() => { setTagModal(true) }}>Add New Hashtag?</a>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {(selectedAssetType.type !== "Banner" && company_logo?.isBuyNow !== 0) && (
                      <>
                        {subcategories && (
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-0">
                              Buy Now Link
                            </Form.Label>

                            <Form.Group className="mb-0 ali checkbox-btn d-flex align-item-center m-0">
                              <Form.Check
                                type="checkbox"
                                name="buy-now"
                                checked={buyNowCheck}
                                onChange={(e) => {
                                  setBuyNowCheck(e?.target?.checked)
                                }}
                              />
                            </Form.Group>
                          </div>
                        )}
                      </>
                    )}

                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Images" ||
                    selectedAssetType.type === "Brochure") && (
                      <>
                        {subcategories && (
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-2">
                              {company_logo?.new_login != "tal" ? (company_logo?.isBuyNow !== 0 ? "Fund Name" : "Sub-Categories") : "Product"}
                            </Form.Label>

                            
                            <Multiselect className="input-form"
                              options={subcategories}
                              selectedValues={selectedSubCategory}
                              onSelect={onSelectSubCategory}
                              onRemove={onRemoveSubCategory}
                              displayValue="label"
                              showCheckbox={true}
                              avoidHighlightFirstOption={true}
                              customArrow={<Icon.ChevronDown/>}
                              singleSelect={company_logo?.new_login != "tal" ? false : true}
                              placeholder={company_logo?.new_login != "tal" ? (company_logo?.isBuyNow !== 0 ? "Selected Fund Name":"Selected Sub Categories"):"Selected Products"}
                            />
                          </div>
                        )}
                      </>
                    )}


                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Images") && (
                      <>
                        {assetLibraryLeadGenerationList && (
                          <div className="smartFolder-multiSelect">
                            <Form.Label className="mt-2">
                              Lead Generation
                            </Form.Label>
                            <Form.Select
                              id="lead-genration"
                              as="select"
                              className="greeting mb-3 input-form"
                              value={leadGeneration}
                              onChange={(e) => {
                                setLeadGeneration(e.target.value);
                              }}
                            >
                              <option key={1} value="">Select Lead Generation</option>
                              {assetLibraryLeadGenerationList.map((item, index) => {
                                return (
                                  <>
                                    <option key={index + 2} value={item.id} >{item.lg_type}</option>
                                  </>
                                )
                              })}
                            </Form.Select>
                          </div>
                        )}
                      </>
                    )}

                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Banner" || selectedAssetType.type === "Brochure" ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic") && (
                      <div className="smartFolder-multiSelect m-lg-0 mb-3 custom-flex">
                        <Form.Label>
                          Define URL
                        </Form.Label>
                        <br />
                        <div className="url-max-size">
                          {(selectedAssetType.type != "Brochure" &&
                            selectedAssetType.type != "Infographics" &&
                            selectedAssetType.type != "Infographic") && <>
                            <Form.Group className="mb-3 radio-btn">
                              <Form.Check
                                type="radio"
                                label="Microsite URL"
                                name="define-url"
                                value="M"
                                checked={msUrl}
                                onClick={(e) => {
                                  setCtaCheck(false);
                                  setWaUrl(false);
                                  setScUrl(false);
                                  setLpUrl(false);
                                  setMsUrl(true);
                                  setCusUrl(false);
                                  setNoneUrl(false);
                                  setUrlOption("M");
                                  setSecureUrl(micrositeUrl);
                                  setDefineUrl(micrositeUrl);
                                }}
                              />
                            </Form.Group>
                            
                            <Form.Group className="mb-3 radio-btn ">
                              <Form.Check
                                type="radio"
                                label="Landingpage URL"
                                name="define-url"
                                value="L"
                                checked={lpUrl}
                                onClick={(e) => {
                                  setCtaCheck(false);
                                  setWaUrl(false);
                                  setLpUrl(true);
                                  setScUrl(false);
                                  setMsUrl(false);
                                  setCusUrl(false);
                                  setNoneUrl(false);
                                  setUrlOption("L");
                                }}
                              />
                            </Form.Group>
                          
                            <Form.Group className="mb-3 radio-btn">
                              <Form.Check
                                type="radio"
                                label="Custom URL"
                                name="define-url"
                                value="C"
                                checked={cusUrl}
                                onClick={(e) => {
                                  setCtaCheck(false);
                                  setScUrl(false);
                                  setWaUrl(false);
                                  setLpUrl(false);
                                  setMsUrl(false);
                                  setCusUrl(true);
                                  setNoneUrl(false);
                                  setSecureUrl("");
                                  setUrlOption("C");
                                }}
                              />
                            </Form.Group>
                          
                            { video_cta_flag === true && selectedAssetType.type === "Video" && <Form.Group className="mb-3 radio-btn">
                              <Form.Check
                                type="radio"
                                label="CTA"
                                name="cta"
                                value="V"
                                checked={ctaCheck}
                                onClick={(e) => {
                                  setCtaCheck(true);
                                  setScUrl(false);
                                  setWaUrl(false);
                                  setLpUrl(false);
                                  setMsUrl(false);
                                  setCusUrl(false);
                                  setNoneUrl(false);
                                  setUrlOption("V");
                                }}
                              />
                            </Form.Group>}
                          
                            { talic_active != "tal" && <Form.Group className="mb-3 radio-btn">
                              <Form.Check
                                type="radio"
                                label="Showcase URL"
                                name="define-url"
                                value={"S"}
                                checked={scUrl}
                                onClick={(e) => {
                                  setCtaCheck(false);
                                  setWaUrl(false);
                                  setScUrl(true);
                                  setLpUrl(false);
                                  setMsUrl(false);
                                  setCusUrl(false);
                                  setNoneUrl(false);
                                  setUrlOption("S");
                                }}
                              />
                            </Form.Group>}
                          </>}
                          <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Magic Link"
                              name="whatsapp-url"
                              value={"W"}
                              checked={waUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setScUrl(false);
                                setWaUrl(true);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("W");
                              }}
                            />
                          </Form.Group>
                          
                          <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="None"
                              name="define-url"
                              value="N"
                              checked={noneUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setWaUrl(false);
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(true);
                                setUrlOption(null);
                                setSecureUrl("");
                                setDefineUrl("");
                              }}
                            />
                          </Form.Group>
                          {lpUrl && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                setSecureUrl(
                                  landingSecureUrl +
                                  e.target.value
                                );
                                setDefineUrl(e.target.value);
                              }}
                            >
                              <option value="">Select Landing URL</option>
                              {landingpageUrlList &&
                                landingpageUrlList.map((lp) => {
                                  return (
                                    <>
                                      <option
                                        id={lp.publish_page_id}
                                        value={lp.publish_page_name}
                                      >
                                        {lp.publish_page_name}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}
                      
                          {waUrl && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                setWhatsappType(e.target.value);
                              }}
                            >
                              <option value="">Select Magic Link</option>
                              {whatsapppageUrlList &&
                                whatsapppageUrlList?.map((wa) => {
                                  return (
                                    <>
                                      <option
                                        id={wa.id}
                                        value={wa.id}
                                      >
                                        {wa.name}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}
                          
                          {ctaCheck && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                setVideoType(e.target.value);
                              }}
                            >
                              <option value="">Select CTA</option>
                              {cta_asset_list &&
                                cta_asset_list.map((lp) => {
                                  return (
                                    <>
                                      <option
                                        id={lp.id}
                                        value={lp.id}
                                      >
                                        {lp.ctaName}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}
                        
                          {scUrl && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                setSecureUrl(
                                  showcaseSecureUrl +
                                  e.target.value
                                );
                                setDefineUrl(e.target.value);
                              }}
                            >
                              <option value="">Select Showcase URL</option>
                              {showcaseUrlList &&
                                showcaseUrlList.map((sc) => {
                                  return (
                                    <>
                                      <option
                                        id={sc.id}
                                        value={sc.case_study_title}
                                      >
                                        {sc.case_study_title}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}
                          {msUrl && (
                            <Form.Group className="ms-cus-url">
                              <Form.Control
                                type="text"
                                ref={msRef}
                                autoComplete="off"
                                // required
                                value={micrositeUrl}
                                className="mb-3"
                              />
                            </Form.Group>
                          )}
                          {cusUrl && (
                            <Form.Group className="ms-cus-url">
                              <Form.Control
                                type="text"
                                ref={cusRef}
                                autoComplete="off"
                                // required
                                placeholder="Enter Custom URL"
                                onChange={(e) => {
                                  setSecureUrl(e.target.value);
                                  setDefineUrl(e.target.value);
                                  setCustUrl(e.target.value);
                                }}
                                className="mb-3"
                              />
                            </Form.Group>
                          )}
                        </div>
                      </div>
                    )}

                  {ctaCheck && <div className="smartFolder-multiSelect m-lg-0 mb-3 custom-flex">
                    <Form.Label>
                      Form Type
                    </Form.Label>
                    <br />
                    <div className="url-max-size">
                      <Form.Group className="mb-3 radio-btn">
                        <Form.Check
                          type="radio"
                          label="Sequential Form"
                          name="Sequential_Form"
                          value="sequential_form"
                          checked={formType === "sequential_form"}
                          onClick={(e) => {
                            setFormType(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 radio-btn">
                        <Form.Check
                          type="radio"
                          label="Single Form"
                          name="Single_Form"
                          value="single_form"
                          checked={formType === "single_form"}
                          onClick={(e) => {
                            setFormType(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>}


                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
                    ((!(platformTW || platformLN) || platformFB)) && <Form.Group className="mt-2 caption smartFolder-multiSelect" controlId="formTextarea">

                      <Form.Label className="mt-2 ">
                        Long Caption <span className="mandatory">*</span>
                      </Form.Label>
                      <Form.Control className="input-form"
                        as="textarea"
                        rows={7}
                        required
                        placeholder="Long Caption"
                        ref={longCaptionRef}
                        onChange={(e) => {
                          setLongCaption(e.target.value);
                        }}
                      />
                    </Form.Group>
                  )}

                  {(selectedAssetType.type === "Brochure" ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic" ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Banner") && (
                      <Form.Group className="mb-3 caption smartFolder-multiSelect" controlId="formTextarea">
                        <Form.Label className="mt-2">
                          Description
                        </Form.Label>
                        <Form.Control className="input-form"
                          as="textarea"
                          rows={7}
                          placeholder="Description"
                          ref={descRef}
                          onChange={(e) => {
                            setDescCaption(e.target.value);
                          }}
                        />
                      </Form.Group>
                    )}

                  {(selectedAssetType.type === "Brochure" ||
                    selectedAssetType.type === "Infographics" ||
                    selectedAssetType.type === "Infographic") && (
                      <div className="smartFolder-multiSelect">
                        <Form.Label className="mt-2">
                          Content Type
                        </Form.Label>
                        <Form.Select
                          className="greeting mb-3 input-form"
                          aria-label="select-type"
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                          }}
                          value={selectedType}
                        >
                          <option value={null}>Select Content Type</option>
                          {contentTypeList &&
                            contentTypeList.map((c) => {
                              return <option value={c.id}>{c.article_type}</option>;
                            })}
                        </Form.Select>
                      </div>
                    )}

                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (

                    (platformTW || platformLN) && (<>
                      <Form.Group className="mb-3 caption smartFolder-multiSelect" controlId="formTextarea">
                        <Form.Label className="mt-4">
                          Short Caption <span className="mandatory">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          required
                          rows={7}
                          placeholder="Short Caption"
                          ref={shortCaptionRef}
                          onChange={(e) => {
                            setShortCaption(e.target.value);
                          }}
                          className="mt-3 input-form"
                        />
                      </Form.Group>
                    </>)

                  )}

                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video") && (
                      <div className="smartFolder-multiSelect">
                        <Form.Label className="mt-3">
                          Greetings
                        </Form.Label>
                        <Form.Select
                          className="mt-3 mb-3 greeting input-form"
                          aria-label="select-greetings"
                          onChange={(e) => {
                            setSelectedGreeting(e.target.value);
                          }}
                          value={selectedGreeting}
                        >
                          <option value="empty">Select Greetings</option>
                          {greetingList &&
                            greetingList.map((greeting) => {
                              return (
                                <>
                                  <option value={greeting.greetingID}>
                                    {greeting.greeting_name}
                                  </option>
                                </>
                              );
                            })}
                        </Form.Select>
                      </div>
                    )}
                    { business_flag === true && business_need_list?.data && (
                      <div className="smartFolder-multiSelect mb-4">
                        <Form.Label className="mt-2">
                          Business Need
                        </Form.Label>
                        <Form.Select
                          className="greeting"
                          aria-label="select-type"
                          onChange={(e) => {
                            setBusinessType(e.target.value);
                          }}
                          value={businessType}
                        >
                          <option value={null}>Select Business Need</option>
                          {business_need_list?.data &&
                            business_need_list.data.map((c) => {
                              return <option value={c.id}>{c.type}</option>;
                            })}
                        </Form.Select>
                      </div>
                    )}

                  <div className="custom-btn">
                    <Button
                      variant="info"
                      type="submit"
                      className="save-proceed-btn m-lg-0 mb-3"
                    >
                      Save & Proceed
                      <span>
                        <Icon.ChevronRight />
                      </span>
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add more</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
              selectedAssetType.type === "Banner") && (
                <Card className="bulk-upload">
                  <Card.Body>
                    <Card.Title>
                      Bulk Upload <Icon.Upload />
                    </Card.Title>
                    <Card.Text>supported formats: .png & .jpg</Card.Text>
                    <div className="files-upload-wrapper">
                      <FileInputComponent
                        labelText=""
                        multiple={true}
                        callbackFunction={(file_arr) => {
                          setAddMoreData(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept="image/*"
                      />
                    </div>
                  </Card.Body>
                </Card>
              )}
            {(selectedAssetType.type === "Brochure" ||
              selectedAssetType.type === "Infographics" ||
              selectedAssetType.type === "Infographic") && (
                <Card className="bulk-upload">
                  <Card.Body>
                    <Card.Title>
                      Bulk Upload <Icon.Upload />
                    </Card.Title>
                    <Card.Text>supported formats: .pdf</Card.Text>
                    <div className="files-upload-wrapper pdf">
                      <FileInputComponent
                        labelText=""
                        multiple={true}
                        callbackFunction={(file_arr) => {
                          setAddMoreData(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept=".pdf"
                      />
                    </div>
                  </Card.Body>
                </Card>
              )}
            {selectedAssetType.type === "Video" && (
              <Card className="bulk-upload">
                <Card.Body>
                  <Card.Title>
                    Bulk Upload <Icon.Upload />
                  </Card.Title>
                  <Card.Text>supported formats: .mp4 (maximum size: 10MB) </Card.Text>
                  <div className="files-upload-wrapper video">
                    <FileInputComponent
                      labelText=""
                      multiple={true}
                      callbackFunction={(file_arr) => {
                        setAddMoreData(file_arr);
                      }}
                      // accept="image/*,.pdf,video/*"
                      accept="video/*"
                    />
                  </div>
                </Card.Body>
              </Card>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={addMoreHandler}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </Card >
      <Modal show={tagModal} onHide={() => setTagModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add New HashTag</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>HashTag name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="HashTag Name"
              onChange={(e) => setTagName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addHashTag()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={personalizeModal} onHide={() => setPersonalizeModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add Personalize Name</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>Personalize Video Name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Personalize Video Name"
              onChange={(e) => setPersonalizeName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addPersonalizeName()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={personalizeDeleteModal} onHide={() => setPersonalizeDeleteModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Delete Personalize Name</div>
        </Modal.Header>
        <Modal.Body>
          {personalizeList?.preVideoList?.length > 0 && personalizeList.preVideoList.map((p_v) => {
            return (
              <>
                {p_v.deleted_by === 1 && <div className="d-flex">
                  <input
                      className="url-input"
                      type="checkbox"
                      onChange={(e) => handleUrlCheckboxChange(e,p_v)}
                      checked={selectedPersonalizeCheck.includes(p_v)}
                    />
                  <div style={{paddingLeft:"10px"}}>{ p_v.greeting_name }</div>
                </div>}
              </>
            );
          }) }
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => deletePersonalizeName()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


    </div >
  );
};

export default GlobalFields;
