import { useState, useEffect } from "react";
import Pagination from 'react-responsive-pagination';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchProfileListing, fetchReviewListing } from "../../../redux/actions/businessAction";

const BusinessPagination = (props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const totalData = Math.ceil(props.total_page / 20);

  const search_input = useSelector((state) => state.mybusiness.search_input);
  const resetPage = useSelector((state) => state.assetLibrary.resetPage);

  function handlePageChange(page) {
      setCurrentPage(page);
      let currOffset = page;
      let from_time = props.startDate != "" ? moment(props.startDate).format('DD-MM-YYYY') : "";
      let to_time = props.endDate != "" ? moment(props.endDate).format('DD-MM-YYYY') : "";
    
      from_time = props.startDate != "" ? moment(props.startDate).format('DD-MM-YYYY') : "";
      to_time = props.endDate != "" ? moment(props.endDate).format('DD-MM-YYYY') : "";
    
      switch(props.currPage) {
      case 'review':
        dispatch(fetchReviewListing(props.authToken, 0, search_input, currOffset, props.timeFilter, from_time, to_time));
        break;
      case 'profile':
        dispatch(fetchProfileListing(props.authToken, search_input, currOffset, props.timeFilter, from_time, to_time));
        break;  
      default:
        break;
    }
  }

  useEffect(() => {
    setCurrentPage(props.page);
  }, [props.page]);
    
  useEffect(() => {
    console.log("PageReset", resetPage);
  }, [resetPage]);

  return (
    <div className="w-50 full-width mt-3">
        <Pagination
            className="pagination justify-content-start"  
            total={totalData}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
        />
    </div>
  );
};

export default BusinessPagination;
