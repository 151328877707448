import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deletePostReply, submitPostReply } from "../../../redux/actions/businessAction";

export default function SubTable(props) {
    const dispatch = useDispatch()
    const [ownerReply, setOwnerReply] = useState("")
    const handlePost = () => {
      if (ownerReply != "") {
        dispatch(submitPostReply(props.authToken, ownerReply, props.pid))
        setOwnerReply("");
      }
    }
    return (
      <>
        <div className="text-left color-theme">
          <Row className="main-div-theme">
            <Col md={props.replySection == props.pid ? 7 : 12} className="review-container container-right">
              <div className='right-response ml-auto mr-3 mb-3'>
                {props.data.comment !="" && <p className='ai-response mb-1'>{ props.data.comment }</p>}
                <div className="d-flex align-items-center mb-2">
                  <div className="time-style">
                    <div className="d-flex gap-1">
                      {[...Array(5)].map((_, index) => {
                        // Convert the rating string to a numeric value
                        const numericRating = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 }[props.data?.rating.toUpperCase()] || 0;

                        // Determine the star color
                        const color = index < numericRating ? "#fec400" : "#eceaea";

                        return <Icon.StarFill className={`${props.data.comment =="" ? "review-icon-dim":"review-icon-dim"}`} key={index} color={color} />;
                      })}
                    </div>
                  </div>
                </div>
                <div className="reply-response"><p className="mb-0">{ props.data.reviewer_name }</p><p className="mb-0">{ props.data.comment_date }</p></div>
              </div>
              {props.data.reply != "" && <div className={"first-left-response ml-4 mb-3 last-response"}> 
                <Dropdown style={{ position: 'absolute', right: '4px', top: '6px' }}>
                  <Dropdown.Toggle 
                    variant="secondary" 
                    id="dropdown-basic"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      padding: '0',
                    }}
                  >
                    <Icon.ThreeDotsVertical color="black" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                      props.handleInnerTable(props.pid);
                      setOwnerReply(props.data.reply)
                    }}
                    >Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      dispatch(deletePostReply(props.authToken, props.pid))
                    }}
                    >Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <p className='ai-response mb-2'>
                    {props.data.reply}
                </p>
                <div className="reply-response"><p className="mb-0">Owner</p><p className="mb-0">{ props.data.reply_date }</p></div>
              </div>}
            </Col>
            {props.replySection == props.pid && <Col className="review-container border-review container-right mt-2 mb-1 bg-white">
              <p className="write-review">Write a reply to the review.</p>
              <Form>
                <Form.Group className="mb-1 caption smartFolder-multiSelect" controlId="formTextarea">
                  <Form.Control className="input-form"
                    as="textarea"
                    rows={6}
                    value={ownerReply}
                    onChange={(e) => {
                      setOwnerReply(e.target.value)
                    }}
                    placeholder="Share details of your own experience"
                  />
                </Form.Group>
              </Form>
              <span className="notify-reviews mb-3">Posting publicly across Google</span>
              <div className="w-full d-flex justify-content-end">
                <div className="gap-2 d-flex">
                  <Button variant="secondary border-0" onClick={() => { setOwnerReply(""); props.handleCancel()}} style={{backgroundColor:"#b0b0b0"}}>
                    Cancel
                  </Button>
                  <Button variant="primary border-0" onClick={handlePost} style={{backgroundColor:"#45bcd2"}}>
                    Post
                  </Button>
                </div>
              </div>
            </Col>}
          </Row>
        </div>
      </>
    );
}
