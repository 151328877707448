import * as Icon from "react-bootstrap-icons";
import SubTable from "./SubTable"
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";

export default function ReviewTable(props) {
  const [review, setReview] = useState(props.categoryData);
  const [analyticsFlag, setAnalyticsFlag] = useState(false);
  const [replySection, setReplySection] = useState(false);
  const [reviewId, setReviewId] = useState(false);
  const authToken = props.authToken;
  
  const handleInnerTable = (id) => {
    setReplySection(id);
    setReviewId(id);
    setAnalyticsFlag(true);
  }
  
  const handleCancel = () => {
    setReplySection(null);
  }
  
  return (
    <div className="App">
        <div className="table borderd">
              <div
                style={{ lineHeight: "35px" }}
                className=""
              >
                {review && review?.map((rev, index) => (
                  <>
                    <div className="row border-bottom">
                      <div style={{textAlign: "left", fontSize: "10px"}} className="table-row-count time-date-div">
                        <p>{ rev.date?.replace("-"," ") }</p>
                        <p style={{textTransform:"uppercase"}} className="disable-date">{ rev.time?.replace("-"," ") }</p>
                      </div>
                      <div className="table-row-count image-div">
                        <img style={{width:"50px", height:"50px"}} src={rev.profile_photo} alt="_"/>
                      </div>
                      <div className="text-aligning table-name">
                        <span className="title-main text-capitalize" title={rev.reviewer_name}>{ rev.reviewer_name }</span>
                      </div>
                      <div className="adjust-width text-aligning">
                        <div className="time-style">
                          <div className="d-flex gap-1">
                            {[...Array(5)].map((_, index) => {
                              // Convert the rating string to a numeric value
                              const numericRating = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 }[rev.rating.toUpperCase()] || 0;

                              // Determine the star color
                              const color = index < numericRating ? "#fec400" : "#eceaea";

                              return <Icon.StarFill className="review-icon-dim" key={index} color={color} />;
                            })}
                          </div>
                        </div>
                      </div>
                      <div style={{width:"35%"}} className="text-none date review-aligning">
                        <div className="bordering-date time-style">
                          <div>
                            {rev.comment.length > 150 ? rev.comment.slice(0, 150) + "..." : rev.comment || <i className="no-comment">No review</i>}
                            {rev.comment.length > 150 && <span className="read-more-gmb" onClick={() => {
                              setAnalyticsFlag(!analyticsFlag);
                              if (reviewId) {
                                setReviewId(null);
                                setReplySection(null);
                              } else {
                                setReviewId(rev?.id)
                              }
                            }}>{reviewId != rev.id ? "Read more":"Read less"}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="review-action-outer col d-flex justify-content-end align-items-center">
                        {rev.reply == "" && <div className="right-bar-icon">
                          <span className="edit-icon"
                            onClick={() => { setReplySection(rev.id); setReviewId(rev.id); setAnalyticsFlag(true); }}
                          >
                            <Icon.Reply title="Reply" color={replySection != rev.id ?"rgba(33, 33, 33, 0.3)":"#45bcd2"}/>
                          </span>
                        </div>}
                        <div className="right-bar-icon">
                          <div
                            className="edit-icon"
                            onClick={() => {
                              setAnalyticsFlag(!analyticsFlag);
                              if (reviewId) {
                                setReviewId(null);
                                setReplySection(null);
                              } else {
                                setReviewId(rev?.id)
                              }
                            }}
                          >
                                {reviewId != rev.id ? <Icon.ChevronDown color={reviewId != rev.id ?"rgba(33, 33, 33, 0.3)":"#45bcd2"} /> : <Icon.ChevronUp color={reviewId != rev.id ?"rgba(33, 33, 33, 0.3)":"#45bcd2"}/>}
                          </div>
                        </div>
                      </div>
                      
                      {(analyticsFlag === true && reviewId === rev.id) &&
                        <SubTable authToken={authToken} page={props.page} subPageName={props.subPageName}  pid={reviewId} data={rev} replySection={replySection} handleCancel={handleCancel} handleInnerTable={handleInnerTable}/>
                      }
                    </div>
                  </>
                ))}
              </div>
        </div>
    </div>
  );
}
